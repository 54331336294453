import { ofType } from 'redux-observable';
import {
  catchError, concatMap, mergeMap, startWith,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ADD_INVITATION_USERS } from '../../../types/user';
import * as types from '../../../types/user';
import { findUserAll } from '../../../utils/api/invitations';
import {
  addInvitationUsersFulfilled, clearFailUser, failUser, fetchingFinishedUser, fetchingUser,
} from '../../actions/user';

export const addInvitationUsersEpic = (action$: any) =>
  action$.pipe(
    ofType(ADD_INVITATION_USERS),
    mergeMap((action: types.AddInvitationUsersAction) =>
      from(findUserAll(action.payload.invitedCompanyid, action.payload.query)).pipe(
        concatMap((response) => of(
          addInvitationUsersFulfilled(response.users, response.company, response.count),
          fetchingFinishedUser(),
          clearFailUser(),
        )),
        startWith(fetchingUser()),
        catchError((errors) => of(
          failUser(errors.response.data.errors),
          fetchingFinishedUser(),
        )),
      )),
  );
