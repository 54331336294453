// steps完了時のepic
import { ofType } from 'redux-observable';
import {
  catchError, concatMap, endWith, mergeMap, startWith,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import { push } from 'connected-react-router';
import { SIGNUP_COMPLETE_SESSION } from '../../../types/session';
import * as types from '../../../types/session';
import { addNotification } from '../../actions/notification';
import {
  addSessionFulfilled,
  clearFailSession,
  failSession,
  fetchingFinishedSession,
  fetchingSession,
} from '../../actions/session';
import { addConfigFulfilled } from '../../actions/config';
import { User } from '../../../types/user';
import { Company } from '../../../types/company';
import { Me } from '../../../types/me';
import { signUpComplete } from '../../../utils/api/session';
import { findMe } from '../../../utils/api/me';

const handleSignUpComplete = async (user: User, company: Company): Promise<Me> => {
  try {
    await signUpComplete(user, company);
    return await findMe();
  } catch (e) {
    return Promise.reject(e);
  }
};

export const registerSessionEpic = (action$: any) =>
  action$.pipe(
    ofType(SIGNUP_COMPLETE_SESSION),
    mergeMap((action: types.SignupCompleteSessionAction) =>
      from(handleSignUpComplete(action.payload.user, action.payload.company)).pipe(
        concatMap((response) => of(
          // TODO: i18n対応。reduxで使うとハマる
          addNotification({ type: 'success', message: '登録が完了しました。HRbase PPOのサービスをご利用いただけます。' }),
          addSessionFulfilled(response),
          addConfigFulfilled(response?.config),
          clearFailSession(),
          push(response?.config?.rootMenu?.path),
        )),
        startWith(fetchingSession()),
        catchError((errors) => of(
          failSession(errors.response.data.errors),
          push('login'),
        )),
        endWith(fetchingFinishedSession()),
      )),
  );
