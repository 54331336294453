import React from 'react';
import Notification from './Notification';
import Header from './Header';
import NotificationForReactQuery from './NotificationForReactQuery';

const Layout: React.FC = ({ children }): JSX.Element => (
  <>
    <Notification />
    <NotificationForReactQuery />
    <Header />
    { children }
  </>
);

export default Layout;
