// steps完了時のepic
import { ofType } from 'redux-observable';
import {
  catchError, concatMap, endWith, mergeMap, startWith,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import { push } from 'connected-react-router';
import { CLIENT_SIGNUP_COMPLETE_SESSION } from '../../../types/session';
import * as types from '../../../types/session';
import {
  addSessionFulfilled,
  clearFailSession,
  failSession,
  fetchingFinishedSession,
  fetchingSession,
} from '../../actions/session';
import { addConfigFulfilled } from '../../actions/config';
import { signUpComplete } from '../../../utils/api/session';
import { addNotification } from '../../actions/notification';

export const clientRegisterSessionEpic = (action$: any) =>
  action$.pipe(
    ofType(CLIENT_SIGNUP_COMPLETE_SESSION),
    mergeMap((action: types.SignupCompleteSessionAction) =>
      from(signUpComplete(action.payload.user, action.payload.company)).pipe(
        concatMap((response) => of(
          addNotification({ type: 'success', message: '登録が完了しました。HRbase PPOのサービスをご利用いただけます。再度ログインしてください。' }),
          addSessionFulfilled(response),
          addConfigFulfilled(response?.config),
          clearFailSession(),
          // TODO: 別issueでProuser追加時と併せて対応
          push('/login'),
        )),
        startWith(fetchingSession()),
        catchError((errors) => of(failSession(errors.response.data.errors))),
        endWith(fetchingFinishedSession()),
      )),
  );
