import * as types from '../../types/article';
import { ErrorMessage } from '../../types/error';
import { Article } from '../../types/article';

export const addArticleForDraft = (article: Article): types.ArticleActionTypes => (
  { type: types.ADD_ARTICLE_FOR_DRAFT, payload: { article } }
);

export const addArticleForSend = (article: Article): types.ArticleActionTypes => (
  { type: types.ADD_ARTICLE_FOR_SEND, payload: { article } }
);

export const addArticleFulfilled = (article: types.Article): types.ArticleActionTypes => (
  { type: types.ADD_ARTICLE_FULFILLED, payload: { article } }
);

export const addArticlesForSent = (query: string): types.ArticleActionTypes => (
  { type: types.ADD_ARTICLES_FOR_SENT, payload: { query } }
);

export const addArticlesForReceive = (query: string): types.ArticleActionTypes => (
  { type: types.ADD_ARTICLES_FOR_RECEIVE, payload: { query } }
);

export const addArticlesFulfilled = (
  articles: Array<types.Article>,
  count: string,
): types.ArticleActionTypes => (
  { type: types.ADD_ARTICLES_FULFILLED, payload: { articles, count } }
);

export const updateArticleForDraft = (id: number, article: Article): types.ArticleActionTypes => (
  { type: types.UPDATE_ARTICLE_FOR_DRAFT, payload: { id, article } }
);

export const updateArticleForSend = (id: number, article: Article): types.ArticleActionTypes => (
  { type: types.UPDATE_ARTICLE_FOR_SEND, payload: { id, article } }
);

export const updateArticleFulfilled = (article: types.Article): types.ArticleActionTypes => (
  { type: types.UPDATE_ARTICLE_FULFILLED, payload: { article } }
);

export const deleteArticle = (id: number): types.ArticleActionTypes => (
  { type: types.DELETE_ARTICLE, payload: { id } }
);

export const deleteArticleFulfilled = (id: number): types.ArticleActionTypes => (
  { type: types.DELETE_ARTICLE_FULFILLED, payload: { id } }
);

export const activateArticle = (id: number): types.ArticleActionTypes => (
  { type: types.ACTIVATE_ARTICLE, payload: { id } }
);

export const activateArticleFulfilled = (id: number): types.ArticleActionTypes => (
  { type: types.ACTIVATE_ARTICLE_FULFILLED, payload: { id } }
);

export const likeArticle = (id: number): types.ArticleActionTypes => (
  { type: types.LIKE_ARTICLE, payload: { id } }
);

export const likeArticleFulfilled = (id: number): types.ArticleActionTypes => (
  { type: types.LIKE_ARTICLE_FULFILLED, payload: { id } }
);

export const unLikeArticle = (id: number): types.ArticleActionTypes => (
  { type: types.UN_LIKE_ARTICLE, payload: { id } }
);

export const unLikeArticleFulfilled = (id: number): types.ArticleActionTypes => (
  { type: types.UN_LIKE_ARTICLE_FULFILLED, payload: { id } }
);

export const fetchingArticle = (): types.ArticleActionTypes => (
  { type: types.FETCHING_ARTICLE, payload: {} }
);

export const fetchingFinishedArticle = (): types.ArticleActionTypes => (
  { type: types.FETCHING_FINISHED_ARTICLE, payload: {} }
);

export const failArticle = (errors: ErrorMessage[]): types.ArticleActionTypes => (
  { type: types.FAIL_ARTICLE_REQUEST, payload: { errors } }
);

export const clearFailArticle = (): types.ArticleActionTypes => (
  { type: types.CLEAR_FAIL_ARTICLE_REQUEST, payload: {} }
);
