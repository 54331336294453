import { combineEpics, ofType } from 'redux-observable';
import {
  catchError, concatMap, endWith, mergeMap, startWith,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import { push } from 'connected-react-router';
import * as types from '../../types/invitation';
import {
  ADD_INVITATION, ADD_INVITATIONS, DELETE_INVITATION, ARCHIVE_INVITATION,
} from '../../types/invitation';
import {
  fetchingInvitation, fetchingFinishedInvitation, addInvitationsFulfilled, addInvitationFulfilled,
  failInvitation, clearFailInvitation, deleteInvitationFulfilled, archiveInvitationFulfilled,
  activateInvitationFulfilled,
} from '../actions/invitation';
import {
  findAll, destroy, save, activate, archive,
} from '../../utils/api/invitations';
import { addNotification } from '../actions/notification';
import { closeModal, openModal } from '../actions/modal';

export const addInvitationEpic = (action$: any) =>
  action$.pipe(
    ofType(ADD_INVITATION),
    mergeMap((action: types.AddInvitationAction) =>
      from(save(action.payload.invitationForPost)).pipe(
        concatMap((response) => of(
          addInvitationFulfilled(response),
          fetchingFinishedInvitation(),
          clearFailInvitation(),
          closeModal(),
          // TODO: i18n対応。reduxで使うとハマる
          openModal({
            title: '企業を追加しました。', message: '一覧画面からご確認ください。', type: 'success', buttons: true,
          }),
        )),
        startWith(fetchingInvitation()),
        catchError((errors) => of(failInvitation(errors.response.data.errors))),
        endWith(fetchingFinishedInvitation()),
      )),
  );

export const addInvitationsEpic = (action$: any) =>
  action$.pipe(
    ofType(ADD_INVITATIONS),
    mergeMap((action: types.AddInvitationsAction) =>
      from(findAll(action.payload.query)).pipe(
        concatMap((response) => of(
          addInvitationsFulfilled(response.invitations, response.count),
          fetchingFinishedInvitation(),
          clearFailInvitation(),
        )),
        startWith(fetchingInvitation()),
        catchError((errors) => of(failInvitation(errors.response.data.errors))),
        endWith(fetchingFinishedInvitation()),
      )),
  );

export const deleteInvitationEpic = (action$: any) =>
  action$.pipe(
    ofType(DELETE_INVITATION),
    mergeMap((action: types.DeleteInvitationAction) =>
      from(destroy(action.payload.invitedCompanyId)).pipe(
        concatMap(() => of(
          deleteInvitationFulfilled(action.payload.invitedCompanyId),
          fetchingFinishedInvitation(),
          clearFailInvitation(),
          // TODO: i18n対応。reduxで使うとハマる
          addNotification({ message: '非表示にしました。', type: 'success' }),
          push('/pro/invitations?onlyDeleted=true'),
        )),
        startWith(fetchingInvitation()),
        catchError((errors) => of(failInvitation(errors.response.data.errors))),
        endWith(fetchingFinishedInvitation()),
      )),
  );

export const archiveInvitationEpic = (action$: any) =>
  action$.pipe(
    ofType(ARCHIVE_INVITATION),
    mergeMap((action: types.ArchiveInvitationAction) =>
      from(archive(action.payload.invitedCompanyId)).pipe(
        concatMap(() => of(
          archiveInvitationFulfilled(action.payload.invitedCompanyId),
          fetchingFinishedInvitation(),
          clearFailInvitation(),
          closeModal(),
          // TODO: i18n対応。reduxで使うとハマる
          addNotification({ message: 'アーカイブしました。', type: 'success' }),
        )),
        startWith(fetchingInvitation()),
        catchError((errors) => of(failInvitation(errors.response.data.errors))),
        endWith(fetchingFinishedInvitation()),
      )),
  );

export const activateInvitationEpic = (action$: any) =>
  action$.pipe(
    ofType(types.ACTIVATE_INVITATION),
    mergeMap((action: types.ActivateInvitationsAction) =>
      from(activate(action.payload.invitedOwnerUserId)).pipe(
        concatMap(() => of(
          activateInvitationFulfilled(action.payload.invitedOwnerUserId),
          fetchingFinishedInvitation(),
          clearFailInvitation(),
          // TODO: i18n対応。reduxで使うとハマる
          addNotification({ message: 'アクティブにしました。', type: 'success' }),
          closeModal(),
          // NOTE: query paramsを動的に取得するのはかなり難しいので、固定値で対応
        )),
        startWith(fetchingInvitation()),
        catchError((errors) => of(failInvitation(errors.response.data.errors))),
        endWith(fetchingFinishedInvitation()),
      )),
  );

export default combineEpics(
  addInvitationEpic, addInvitationsEpic, deleteInvitationEpic, archiveInvitationEpic,
  activateInvitationEpic,
);
