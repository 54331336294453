import { ExclamationIcon } from '@heroicons/react/outline';
import React from 'react';

const FailureIcon: React.FC = (): JSX.Element => (
  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
    <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
  </div>
);

export default FailureIcon;
