import { combineEpics } from 'redux-observable';
import sessionEpics from './session/index';
import userEpics from './users/index';
import invitationEpics from './invitation';
import configEpics from './config';
import articleEpics from './articles/index';

export const rootEpic = combineEpics(
  sessionEpics, userEpics, configEpics,
  invitationEpics, articleEpics,
);
