import {
  get, post, put, destroy as requestDestroy,
} from '../requests';
import { Article } from '../../types/article';

export const findAllForSent = async (query: string): Promise<any> => get<Article[]>(`/articles/sents${query}`);
export const findAllForReceive = async (query: string): Promise<any> => get<Article[]>(`/articles/receives${query}`);
export const findOne = async (id: number): Promise<any> => get<Article>(`/articles/${id.toString()}`);
export const findOneForSent = async (id: number): Promise<any> => get<Article>(`/articles/${id.toString()}/sent`);
export const findOneForRecive = async (id: number): Promise<any> => get<Article>(`/articles/${id.toString()}/receive`);
export const findOneForReceiveForPublic = async (uuid: string): Promise<any> => get<Article>(`/articles/public/${uuid.toString()}`);
export const update = async (id: number, article: Article): Promise<any> => put<Article>(`/articles/${id}`, { article });
export const destroy = async (id: number): Promise<any> => requestDestroy<any>(`/articles/${id}`);
export const activate = async (id: number): Promise<any> => put<any>(`/articles/${id}/activate`, {});
export const save = async (article: Article): Promise<any> => post<Article>('/articles', { article });
export const findFileWithId = async (id: number, fileName: string): Promise<any> => get(`/articles/${id.toString()}/file_url?file_name=${fileName}`);
export const like = async (id: number): Promise<any> => put<any>(`/articles/${id}/like`, {});
export const unLike = async (id: number): Promise<any> => put<any>(`/articles/${id}/unlike`, {});
