import React, { forwardRef } from 'react';

const Button = forwardRef<HTMLButtonElement, React.ButtonHTMLAttributes<HTMLButtonElement>>(
  (props, ref): JSX.Element => (
    <button
      {...props}
      ref={ref}
      className="w-full flex justify-center items-center py-2 px-4 border
                  border-transparent rounded-md shadow-sm text-sm
                  font-medium text-white bg-Navy-800 hover:bg-Navy-900
                  focus:outline-none focus:ring-2 focus:ring-offset-2
                  focus:ring-Navy-500"
    />
  ),
);

export default Button;
