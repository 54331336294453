// TODO: 型入れる
// ログインのepic
import { ofType, StateObservable } from 'redux-observable';
import {
  catchError,
  concatMap,
  endWith,
  mergeMap,
  startWith,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import { push } from 'connected-react-router';
import { RootState } from '../../../types/store';
import { ADD_SESSION } from '../../../types/session';
import * as types from '../../../types/session';
import {
  addSessionFulfilled,
  clearFailSession,
  failSession,
  fetchingFinishedSession,
  fetchingSession,
} from '../../actions/session';
import { addConfigFulfilled } from '../../actions/config';
import { addNotification } from '../../actions/notification';
import { firebaseError, login } from '../../../utils/api/firebase';
import { Me } from '../../../types/me';
import { sessionPost } from '../../../utils/api/session';
import { findMe } from '../../../utils/api/me';

const handleLogin = async (email: string, password: string): Promise<Me> => {
  try {
    const result = await login(email, password);
    const token = await result.user?.getIdToken();

    await sessionPost(token);
    return await findMe();
  } catch (e) {
    return Promise.reject(e);
  }
};
const authErrorHandler = (error: any) => {
  if (!error.code && typeof error === 'object') {
    if (error.response?.data?.errors) {
      return failSession(error.response?.data?.errors);
    } else {
      return failSession([{ message: 'エラーが発生しました' }]);
    }
  } else {
    return failSession([{ message: firebaseError(error.code) }]);
  }
};

export const loginSessionEpic = (
  action$: any,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    ofType(ADD_SESSION),
    mergeMap((action: types.AddSessionAction) =>
      from(handleLogin(action.payload.email, action.payload.password)).pipe(
        concatMap((response) =>
          of(
            addSessionFulfilled(response),
            addConfigFulfilled(response?.config),
            clearFailSession(),
            // TODO: i18n対応。reduxで使うとハマる
            addNotification({ message: 'ログインしました！', type: 'success' }),
            push(
              state$.value.router?.location?.state?.from
                || response?.config?.rootMenu?.path,
            ),
          )),
        startWith(fetchingSession()),
        catchError((error) => of(authErrorHandler(error))),
        endWith(fetchingFinishedSession()),
      )),
  );
