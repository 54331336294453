import React from 'react';

const BlueLineButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = (
  props,
): JSX.Element => (
  <button
    {...props}
    className="w-full flex justify-center py-2 px-4 border
                border-Navy-800 rounded-md shadow-sm text-sm
                font-medium text-Navy-800 bg-white
                focus:outline-none focus:ring-2 focus:ring-offset-2
                focus:ring-Navy-500"
  />
);

export default BlueLineButton;
