import { User } from '../../types/user';
import { Company } from '../../types/company';
import { Config } from '../../types/me';
import { Topic } from '../../types/topic';

export const user: User = {
  id: undefined,
  lastName: '',
  firstName: '',
  email: '',
  role: 'General',
  status: 'NonActive',
  createdAt: undefined,
  updatedAt: undefined,
};

export const company: Company = {
  id: undefined,
  name: '',
  tel: '',
  presidentLastName: '',
  presidentFirstName: '',
  presidentTitle: '',
  zip: '',
  prefecture: '',
  city: '',
  street: '',
  building: '',
  contractType: 'Pro',
  owner: {
    id: undefined,
    name: '',
    email: '',
  },
};

export const config: Config = {
  rootMenu: { text: '', path: '', icon: '' },
  headerMenu: [],
  pullDownMenu: [],
  materialMenu: {
    categories: [],
    sources: [],
    tabs: [],
    tabsInPills: [],
  },
  userMenu: {
    tabs: [],
  },
  articleMenu: [],
};

export const topic: Topic = {
  id: 0,
  type: { id: undefined, name: 'open' },
  title: '',
  status: 'resolved',
  clientCompany: company,
  lastAt: undefined,
  createdAt: undefined,
  updatedAt: undefined,
  deletedAt: undefined,
  createdUser: undefined,
  updatedUser: undefined,
  deletedUser: undefined,
};
