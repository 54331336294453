import { combineEpics } from 'redux-observable';
import { addArticleEpicForDraft, addArticleEpicForSend } from './add';
import { addArticlesEpicForSent, addArticlesEpicForReceive } from './add_all';
import { updateArticleEpicForDraft, updateArticleEpicForSend } from './update';
import { deleteArticleEpic } from './delete';
import { likeArticleEpic, unLikeArticleEpic } from './like';

export default combineEpics(
  addArticleEpicForDraft,
  addArticleEpicForSend,
  addArticlesEpicForSent,
  addArticlesEpicForReceive,
  updateArticleEpicForDraft,
  updateArticleEpicForSend,
  deleteArticleEpic,
  likeArticleEpic,
  unLikeArticleEpic,
);
