import { Company } from '../../types/company';
import {
  get, post, put, destroy as requestDestroy,
} from '../requests';
import { Invitation, InvitationForPost } from '../../types/invitation';
import { User } from '../../types/user';

export const findAll = async (query: string): Promise<any> => get<Invitation[]>(`/invitations${query}`);
export const destroy = async (invitedCompanyid: number): Promise<any> => requestDestroy<any>(`/invitations/${invitedCompanyid}`);
export const archive = async (invitedCompanyid: number): Promise<any> => requestDestroy<any>(`/invitations/${invitedCompanyid}/archive`);
export const activate = async (invitedOwnerId: number): Promise<any> => put<any>(`/invitations/${invitedOwnerId}/activate`, {});
export const update = async (invitedOwnerId: number, company: Company): Promise<any> => put<any>(`/invitations/${invitedOwnerId}`, { company });
export const save = async (invitation: InvitationForPost): Promise<any> => post<InvitationForPost>('/invitations', { invitation });
export const findUserAll = async (invitedCompanyid: number, query: string): Promise<any> => get<User[]>(`/invitations/${invitedCompanyid}/users${query}`);
export const createClient = async (invitedCompanyid: number, user: User): Promise<any> => post<User>(`/invitations/${invitedCompanyid}/users`, { user });
