import React from 'react';
import { useTranslation } from 'react-i18next';
import BaseLabel from './BaseLabel';

interface Props {
  label: string;
  required: boolean;
}

const Label: React.FC<Props> = (props) => {
  const { label, required } = props;
  const { t } = useTranslation();
  return (
    <>
      <div className="flex justify-between text-sm">
        <BaseLabel
          {...props}
          htmlFor="email"
          className="block text-lg font-bold text-gray-700 mb-2"
        >
          { label }
        </BaseLabel>
        { required && (
          <p className="mr-2 text-yellow-300 text-sm">{t('page.common.required')}</p>
        )}
      </div>
    </>
  );
};

export default Label;
