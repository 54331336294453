import React from 'react';
import Notification from './Notification';

const PublicLayout: React.FC = ({ children }): JSX.Element => (
  <>
    <Notification />
    { children }
  </>
);

export default PublicLayout;
