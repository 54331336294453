import { ofType, StateObservable } from 'redux-observable';
import {
  catchError, concatMap, mergeMap, startWith,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import { push } from 'connected-react-router';
import * as types from '../../../types/user';
import { archive } from '../../../utils/api/users';
import {
  archiveUserFulfilled, clearFailUser, failUser, fetchingFinishedUser, fetchingUser,
} from '../../actions/user';
import { closeModal, openModal } from '../../actions/modal';
import { RootState } from '../../../types/store';

export const archiveInvitationUserEpic = (action$: any, state$: StateObservable<RootState>) =>
  action$.pipe(
    ofType(types.ARCHIVE_INVITATION_USER),
    mergeMap((action: types.ArchiveInvitationUserAction) =>
      from(archive(action.payload.id)).pipe(
        concatMap(() => of(
          archiveUserFulfilled(action.payload.id),
          clearFailUser(),
          fetchingFinishedUser(),
          closeModal(),
          openModal({
            title: 'ユーザーをアーカイブしました。', message: '', type: 'success', buttons: true,
          }),
          push(`/${state$.value.session.company.contractType.toLowerCase()}/invitations/${action.payload.invitedCompanyid}/users?onlyDeleted=true`),
        )),
        startWith(fetchingUser()),
        catchError((errors) => of(
          failUser(errors.response.data.errors),
          fetchingFinishedUser(),
        )),
      )),
  );
