import React from 'react';
import './App.css';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import history from './utils/history';
import Routes from './navigation/index';
import Store, { persistor } from './redux/store';
import { queryClient } from './config/react_query';
import useTracking from './GTag';

const App: React.FC = () => {
  if (window.location.hostname.includes('proapp.hrbase.jp')) { useTracking('GTM-TND7W7S'); }
  if (window.location.href.includes('login')) {
    window.location.href = `${process.env.REACT_APP_NEXT_PATH}/login`;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={Store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <Routes />
          </ConnectedRouter>
        </PersistGate>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
