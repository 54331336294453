import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { API_PATH } from '../config/api';
import mockAdapter from '../mocks/axios/adapter';
import { KeyVal } from '../types/commons';
import history from './history';
import { refreshToken } from './api/firebase';

const instance = (token: string | undefined): AxiosInstance => {
  const headers: KeyVal = { 'Content-Type': 'application/json' };

  if (token) {
    headers.Authorization = `Token ${token}`;
  }

  const ax = axios.create({
    baseURL: API_PATH, timeout: 15000, headers, adapter: mockAdapter, withCredentials: true,
  });

  ax.interceptors.response.use((response) => response, async (error) => {
    if (error.response.status === 401) {
      const refreshedToken = await refreshToken();
      headers.Authorization = `Token ${refreshedToken}`;
      try {
        await ax.post(`${API_PATH}/session`, {}, { headers });
        return await ax.request(error.config);
      } catch (e) {
        console.error(e);
      }
      history.push('/login');
      return Promise.reject(error);
    }
    return Promise.reject(error);
  });
  return ax;
};

const responseBody = (response: AxiosResponse) => response.data;

export const get = <T, U = unknown>(url: string): Promise<U> =>
  instance(undefined).get<T>(url).then(responseBody);

export const post = <T, U = unknown>(url: string, body: {}, token = ''): Promise<U> =>
  instance(token).post<T>(url, body).then(responseBody);

export const put = <T, U = unknown>(url: string, body: {}): Promise<U> =>
  instance(undefined).put<T>(url, body).then(responseBody);

export const destroy = <T, U = unknown>(url: string): Promise<U> =>
  instance(undefined).delete<T>(url).then(responseBody);
