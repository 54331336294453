import { ErrorMessage } from './error';
import { User } from './user';
import { Company } from './company';
import { Me } from './me';

export const ADD_SESSION = 'ADD_SESSION';
export const ADD_SESSION_FOR_EMAIL_LINK = 'ADD_SESSION_FOR_EMAIL_LINK';
export const ADD_SESSION_FULFILLED = 'ADD_SESSION_FULFILLED';
export const SIGNUP_SESSION = 'SIGNUP_SESSION';
export const SIGNUP_COMPLETE_SESSION = 'SIGNUP_COMPLETE_SESSION';
export const CLIENT_SIGNUP_COMPLETE_SESSION = 'CLIENT_SIGNUP_COMPLETE_SESSION';
export const DELETE_SESSION = 'DELETE_SESSION';
export const DELETE_SESSION_FULFILLED = 'DELETE_SESSION_FULFILLED';
export const FETCHING_SESSION = 'FETCHING_SESSION';
export const FETCHING_FINISHED_SESSION = 'FETCHING_FINISHED_SESSION';
export const FAIL_SESSION_REQUEST = 'FAIL_SESSION_REQUEST';
export const CLEAR_FAIL_SESSION_REQUEST = 'CLEAR_FAIL_SESSION_REQUEST';

// currentUserがuserを修正するときのため
export const UPDATE_USER_SESSION = 'UPDATE_USER_SESSION';
export const UPDATE_USER_SESSION_FULFILLED = 'UPDATE_USER_SESSION_FULFILLED';
// currentUserがcompanyを修正するときのため
export const UPDATE_COMPANY_SESSION = 'UPDATE_COMPANY_SESSION';
export const UPDATE_COMPANY_SESSION_FULFILLED = 'UPDATE_COMPANY_SESSION_FULFILLED';
// passwordをfirebaseで修正するため
export const UPDATE_PASSWORD_SESSION = 'UPDATE_PASSWORD_SESSION';
// emailをfirebaseに修正するときのため
export const UPDATE_EMAIL_SESSION = 'UPDATE_EMAIL_SESSION';
export const UPDATE_EMAIL_SESSION_FULFILLED = 'UPDATE_EMAIL_SESSION_FULFILLED';
// 未認証のオーナーがログイン出来なようにするときのため
export const EMAIL_AUTHENTICATED_SESSION = 'EMAIL_AUTHENTICATED_SESSION';
// 通知を変更するときのため
export const NOTIFICATION_CONFIG_SESSION_FULFILLED = 'NOTIFICATION_CONFIG_SESSION_FULFILLED';

export interface SessionState {
  isLoggedIn: boolean;
  isFetching: boolean;
  isEmailAuthenticated: boolean;
  errors: ErrorMessage[];
  user: User;
  company: Company;
}

export interface AddSessionAction {
  type: typeof ADD_SESSION;
  payload: { email: string, password: string };
}

export interface AddSessionForEmailLinkAction {
  type: typeof ADD_SESSION_FOR_EMAIL_LINK;
  payload: { email: string };
}

export interface AddSessionFulfilledAction {
  type: typeof ADD_SESSION_FULFILLED;
  payload: { me: Me };
}

export interface UpdateUserSessionAction {
  type: typeof UPDATE_USER_SESSION;
  payload: { user: User };
}

export interface UpdateUserSessionFulfilledAction {
  type: typeof UPDATE_USER_SESSION_FULFILLED;
  payload: { user: User };
}

export interface UpdateCompanySessionAction {
  type: typeof UPDATE_COMPANY_SESSION;
  payload: { company: Company };
}

export interface UpdateCompanySessionFulfilledAction {
  type: typeof UPDATE_COMPANY_SESSION_FULFILLED;
  payload: { company: Company };
}

export interface UpdatePasswordSessionAction {
  type: typeof UPDATE_PASSWORD_SESSION;
  payload: { email: string };
}

export interface UpdateEmailSessionAction {
  type: typeof UPDATE_EMAIL_SESSION;
  payload: { password: string, oldEmail: string, newEmail: string };
}

export interface UpdateEmailSessionFulfilledAction {
  type: typeof UPDATE_EMAIL_SESSION_FULFILLED;
  payload: { email: string };
}

export interface SignupSessionAction {
  type: typeof SIGNUP_SESSION;
  payload: { email: string, companyName: string };
}

export interface SignupCompleteSessionAction {
  type: typeof SIGNUP_COMPLETE_SESSION;
  payload: { user: User, company: Company };
}

export interface ClientSignupCompleteSessionAction {
  type: typeof CLIENT_SIGNUP_COMPLETE_SESSION;
  payload: { user: User, company: Company };
}

export interface UpdateNotificationConfigSessionFulfilledAction {
  type: typeof NOTIFICATION_CONFIG_SESSION_FULFILLED;
  payload: { messageAll: boolean, messageMention: boolean };
}

export interface DeleteSessionAction {
  type: typeof DELETE_SESSION;
  payload: {};
}

export interface DeleteSessionFulfilledAction {
  type: typeof DELETE_SESSION_FULFILLED;
  payload: {};
}

export interface FechingSessionAction {
  type: typeof FETCHING_SESSION;
  payload: {};
}

export interface FechingFinishedSessionAction {
  type: typeof FETCHING_FINISHED_SESSION;
  payload: {};
}

export interface FailSessionRequestAction {
  type: typeof FAIL_SESSION_REQUEST;
  payload: { errors: ErrorMessage[] };
}

export interface ClearFailSessionRequestAction {
  type: typeof CLEAR_FAIL_SESSION_REQUEST;
  payload: {};
}

export interface EmailAuthenticatedSessionAction {
  type: typeof EMAIL_AUTHENTICATED_SESSION;
  payload: {};
}

export type SessionActionTypes =
  AddSessionAction |
  AddSessionForEmailLinkAction |
  AddSessionFulfilledAction |
  UpdateUserSessionAction |
  UpdateUserSessionFulfilledAction |
  UpdateCompanySessionAction |
  UpdateCompanySessionFulfilledAction |
  UpdatePasswordSessionAction |
  UpdateEmailSessionAction |
  UpdateEmailSessionFulfilledAction |
  SignupSessionAction |
  SignupCompleteSessionAction |
  ClientSignupCompleteSessionAction |
  UpdateNotificationConfigSessionFulfilledAction |
  DeleteSessionAction |
  DeleteSessionFulfilledAction |
  FechingSessionAction |
  FechingFinishedSessionAction |
  FailSessionRequestAction |
  ClearFailSessionRequestAction |
  EmailAuthenticatedSessionAction;
