import { ofType, StateObservable } from 'redux-observable';
import {
  catchError, concatMap, endWith, mergeMap, startWith,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import { push } from 'connected-react-router';
import { UPDATE_ARTICLE_FOR_DRAFT, UPDATE_ARTICLE_FOR_SEND } from '../../../types/article';
import * as types from '../../../types/article';
import { RootState } from '../../../types/store';
import { update } from '../../../utils/api/articles';
import {
  clearFailArticle,
  failArticle,
  fetchingFinishedArticle,
  fetchingArticle,
  updateArticleFulfilled,
} from '../../actions/article';
import { addNotification } from '../../actions/notification';
import { closeModal, openModal } from '../../actions/modal';

export const updateArticleEpicForDraft = (action$: any, state$: StateObservable<RootState>) =>
  action$.pipe(
    ofType(UPDATE_ARTICLE_FOR_DRAFT),
    mergeMap((action: types.UpdateArticleActionForDraft) =>
      from(update(action.payload.id, action.payload.article)).pipe(
        concatMap((response) => of(
          updateArticleFulfilled(response.article),
          clearFailArticle(),
          addNotification({ message: '記事の下書きを更新しました。', type: 'success' }),
          push(`/${state$.value.session.company.contractType.toLowerCase()}/articles?kind=draft`),
        )),
        startWith(fetchingArticle()),
        catchError((errors) => of(failArticle(errors.response.data.errors))),
        endWith(fetchingFinishedArticle()),
      )),
  );

export const updateArticleEpicForSend = (action$: any) =>
  action$.pipe(
    ofType(UPDATE_ARTICLE_FOR_SEND),
    mergeMap((action: types.UpdateArticleActionForSend) =>
      from(update(action.payload.id, action.payload.article)).pipe(
        concatMap((response) => of(
          clearFailArticle(),
          closeModal(),
          openModal({ title: 'すばらしい！\n情報共有が完了しました！', message: '', type: 'success', buttons: true }),
          updateArticleFulfilled(response.article),
        )),
        startWith(fetchingArticle()),
        catchError((errors) => of(failArticle(errors.response.data.errors))),
        endWith(fetchingFinishedArticle()),
      )),
  );
