import * as types from '../../types/user';
import * as companyTypes from '../../types/company';
import { ErrorMessage } from '../../types/error';

export const addUsers = (query: string): types.UserActionTypes => (
  { type: types.ADD_USERS, payload: { query } }
);

export const addUsersFulfilled = (users: types.User[], count: number): types.UserActionTypes => (
  { type: types.ADD_USERS_FULFILLED, payload: { users, count } }
);

export const addUser = (user: types.User): types.UserActionTypes => (
  { type: types.ADD_USER, payload: { user } }
);

export const addUserFulfilled = (user: types.User): types.UserActionTypes => (
  { type: types.ADD_USER_FULFILLED, payload: { user } }
);

export const updateUser = (user: types.User): types.UserActionTypes => (
  { type: types.UPDATE_USER, payload: { user } }
);

export const updateUserFulfilled = (user: types.User): types.UserActionTypes => (
  { type: types.UPDATE_USER_FULFILLED, payload: { user } }
);

export const deleteUser = (id: number): types.UserActionTypes => (
  { type: types.DELETE_USER, payload: { id } }
);

export const deleteUserFullfilled = (id: number): types.UserActionTypes => (
  { type: types.DELETE_USER_FULFILLED, payload: { id } }
);

export const activateUser = (id: number): types.UserActionTypes => (
  { type: types.ACTIVATE_USER, payload: { id } }
);

export const activateUserFulfilled = (id: number): types.UserActionTypes => (
  { type: types.ACTIVATE_USER_FULFILLED, payload: { id } }
);

export const archiveUser = (id: number): types.UserActionTypes => (
  { type: types.ARCHIVE_USER, payload: { id } }
);

export const archiveInvitationUser = (
  id: number, invitedCompanyid: number,
): types.UserActionTypes => (
  { type: types.ARCHIVE_INVITATION_USER, payload: { id, invitedCompanyid } }
);

export const archiveUserFulfilled = (id: number): types.UserActionTypes => (
  { type: types.ARCHIVE_USER_FULFILLED, payload: { id } }
);

export const addInvitationUsers = (
  invitedCompanyid: number, query: string,
): types.UserActionTypes => (
  { type: types.ADD_INVITATION_USERS, payload: { invitedCompanyid, query } }
);

export const addInvitationUsersFulfilled = (
  users: types.User[], company: companyTypes.Company, count: number,
): types.UserActionTypes => (
  { type: types.ADD_INVITATION_USERS_FULFILLED, payload: { users, company, count } }
);

export const addInvitationUser = (
  invitedCompanyid: number, user: types.User,
): types.UserActionTypes => (
  { type: types.ADD_INVITATION_USER, payload: { invitedCompanyid, user } }
);

export const fetchingUser = (): types.UserActionTypes => (
  { type: types.FETCHING_USER, payload: {} }
);

export const fetchingFinishedUser = (): types.UserActionTypes => (
  { type: types.FETCHING_FINISHED_USER, payload: {} }
);

export const failUser = (errors: ErrorMessage[]): types.UserActionTypes => (
  { type: types.FAIL_USER_REQUEST, payload: { errors } }
);

export const clearFailUser = (): types.UserActionTypes => (
  { type: types.CLEAR_FAIL_USER_REQUEST, payload: {} }
);
