import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../components/organisms/Layout';
import { RootState } from '../types/store';
import Logo from '../assets/img/top_logo.png';

const NotFound: React.FC = (): JSX.Element => {
  const { session } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    if (!(session.isLoggedIn)) {
      dispatch(push('/login'));
    }
  }, []);

  return (
    <Layout>
      <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md mb-5">
          <img
            className="mx-auto w-auto"
            src={Logo}
            alt="Workflow"
          />
        </div>
        <div className="max-w-max mx-auto">
          <main className="sm:flex">
            <p className="text-5xl font-extrabold text-Navy-600 sm:text-5xl">{t('error.status.404')}</p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-5xl font-extrabold text-gray-700 tracking-tight sm:text-5xl">{t('error.notFound.title')}</h1>
                <p className="mt-1 text-base text-gray-500">{t('error.notFound.text')}</p>
              </div>
            </div>
          </main>
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;
