import * as types from '../../types/me';

const initialState: types.ConfigState = {
  rootMenu: { text: '', path: '', icon: '' },
  headerMenu: [],
  pullDownMenu: [],
  materialMenu: {
    categories: [],
    sources: [],
    tabs: [],
    tabsInPills: [],
  },
  userMenu: {
    tabs: [],
  },
  articleMenu: [],
  isFetching: false,
  errors: [],
} as types.ConfigState;

export default function configReducer(
  state = initialState, action: types.ConfigActionTypes,
): types.ConfigState {
  switch (action.type) {
    case types.ADD_CONFIG_FULFILLED: {
      const { config } = action.payload;
      return {
        ...state,
        rootMenu: config?.rootMenu,
        headerMenu: config === undefined ? [] : config?.headerMenu,
        pullDownMenu: config === undefined ? [] : config.pullDownMenu,
        materialMenu: config === undefined ? {
          categories: [],
          sources: [],
          tabs: [],
          tabsInPills: [],
        } : config.materialMenu,
        articleMenu: config === undefined ? [] : config.articleMenu,
        userMenu: config === undefined ? { tabs: [] } : config.userMenu,
      };
    }
    case types.FETCH_CONFIG: {
      return { ...state, isFetching: true };
    }
    case types.FETCHING_FINISHED_CONFIG: {
      return { ...state, isFetching: false };
    }
    case types.FAIL_CONFIG_REQUEST: {
      const { errors } = action.payload;
      return { ...state, errors };
    }
    case types.CLEAR_FAIL_CONFIG_REQUEST: {
      return { ...state, errors: [] };
    }
    default:
      return state;
  }
}
