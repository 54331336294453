import { TypeEnum } from './enum';

export interface Notification {
  type: TypeEnum;
  message: string;
}

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';

export interface NotificationState {
  type: string;
  message: string;
}

export interface AddNotificationAction {
  type: typeof ADD_NOTIFICATION;
  payload: { notification: Notification };
}

export interface ClearNotificationAction {
  type: typeof CLEAR_NOTIFICATION;
  payload: {};
}

export type NotificationActionTypes = AddNotificationAction | ClearNotificationAction;
