import * as types from '../../types/invitation';

const initialState: types.InvitationState = {
  isFetching: false,
  errors: [],
  data: [],
  count: 0,
} as types.InvitationState;

export default function invitationReducer(
  state = initialState, action: types.InvitationActionTypes,
): types.InvitationState {
  switch (action.type) {
    case types.ADD_INVITATIONS_FULFILLED: {
      return { ...state, data: action.payload.invitations, count: action.payload.count };
    }
    case types.ADD_INVITATION_FULFILLED: {
      const { invitation } = action.payload;
      const clone = state.data;
      const { count } = state;
      let addedCount;
      if (count) addedCount = count + 1;
      clone.push(invitation);
      return { ...state, data: clone, count: addedCount };
    }
    case types.DELETE_INVITATION_FULFILLED: {
      const { invitedCompanyId } = action.payload;
      const clone = state.data;
      const index = clone.findIndex((obj: any) => obj.invitedCompany.id === invitedCompanyId);
      if (index !== -1) clone.splice(index, 1);
      return { ...state, data: clone };
    }
    case types.ARCHIVE_INVITATION_FULFILLED: {
      const { invitedCompanyId } = action.payload;
      const clone = state.data;
      const index = clone.findIndex((obj: any) => obj.invitedCompany.id === invitedCompanyId);
      if (index !== -1) clone.splice(index, 1);
      return { ...state, data: clone };
    }
    case types.ACTIVATE_INVITATION_FULFILLED: {
      const { invitedOwnerUserId } = action.payload;
      const clone = state.data;
      const index = clone.findIndex((obj: any) => obj.invitedCompany.id === invitedOwnerUserId);
      if (index !== -1) clone.splice(index, 1);
      return { ...state, data: clone };
    }
    case types.FETCHING_INVITATION: {
      return { ...state, isFetching: true };
    }
    case types.FETCHING_FINISHED_INVITATION: {
      return { ...state, isFetching: false };
    }
    case types.FAIL_INVITATION_REQUEST: {
      const { errors } = action.payload;
      return { ...state, errors };
    }
    case types.CLEAR_FAIL_INVITATION_REQUEST: {
      return { ...state, errors: [] };
    }
    default:
      return state;
  }
}
