import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleIcon } from '@heroicons/react/solid';

import { ErrorMessage } from '../../types/error';

interface Props {
  errors: ErrorMessage[];
}

const Error: React.FC<Props> = (props): JSX.Element => {
  const { errors } = props;
  const { t } = useTranslation();
  const isErrors = errors?.length !== 0 && errors !== undefined;

  return (
    <>
      { isErrors
        && (
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">{t('error.title')}</h3>
                <div className="mt-2 text-sm text-red-700">
                  <ul className="list-disc pl-5 space-y-1">
                    { errors?.map((message) => <li>{ message.message }</li>) }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default Error;
