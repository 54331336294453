import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import CompanyLogo from '../../components/atoms/Logos/CompanyLogo';
import { RootState } from '../../types/store';
import Error from '../../components/organisms/Error';
import Loading from '../../components/atoms/Loading';
import TextInput from '../../components/atoms/Inputs/TextInput';
import Button from '../../components/atoms/Buttons/Button';
import BlueLineButton from '../../components/atoms/Buttons/BlueLineButton';
import { failSession, signupSession, clearFailSession } from '../../redux/actions/session';
import ModalLayout from '../../components/organisms/ModalLayout';
import { setLoginEmail } from '../../utils/localstorage';

interface Props {
  email: string;
  companyName: string;
}

const Modal: React.FC<Props> = (props): JSX.Element => {
  const { email, companyName } = props;
  const dispatch = useDispatch();
  const [showResendLink, setShowResendLink] = useState<boolean>(true);

  const handleResendMail = () => {
    setShowResendLink(false);
    dispatch(signupSession(email, companyName));
  };

  return (
    <ModalLayout>
      { showResendLink
        ? (
          <p className="text-sm text-gray-500 mt-5">
            認証メールが届いていませんか？
            <br />
            迷惑メールフォルダを確認するか、
            <span
              tabIndex={0}
              role="button"
              onClick={handleResendMail}
              className="underline text-Navy-600 hover:text-Navy-800 visited:text-Navy-600 cursor-pointer"
            >
              確認メール再送信依頼
            </span>
            をお願いします。
          </p>
        )
        : (
          <p className="text-sm text-gray-500 mt-5">
            それでも問題が解決しないときは、
            迷惑メールフォルダを確認するか、
            <a href="#" className="underline text-Navy-600 hover:text-Navy-800 visited:text-Navy-600 cursor-pointer">
              お問い合わせ
            </a>
            ください。HRbase PROがお手伝いいたします。
          </p>
        )}
    </ModalLayout>
  );
};

const SignUp: React.FC = (): JSX.Element => {
  const [email, setEmail] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const dispatch = useDispatch();
  const getState = useSelector((state: RootState) => state);
  const { session } = getState;
  const { t } = useTranslation();

  const signup = async (e: React.MouseEvent<HTMLFormElement, MouseEvent>) => {
    e.preventDefault();
    if (email === '' || companyName === '') {
      dispatch(failSession([{ message: t('validation.user.NotEmailAndPassword') }]));
      return;
    }
    setLoginEmail(email);
    dispatch(signupSession(email, companyName));
  };

  useEffect(() => {
    dispatch(clearFailSession());
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <CompanyLogo title={t('pageTitle.signUp')} />
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <Error errors={session?.errors} />
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={signup}>
            <div>
              <TextInput
                id="companyName"
                name="companyName"
                value={companyName}
                type="companyName"
                onChange={(e) => setCompanyName(e.target.value)}
                autoComplete="companyName"
                placeholder={t('attribute.company.name')}
                required
              />
            </div>

            <div>
              <TextInput
                id="email"
                name="email"
                value={email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
                placeholder={t('attribute.user.email')}
                required
              />
            </div>

            <div>
              { session.isFetching ? <Loading /> : <Button>{t('action.new')}</Button> }
            </div>
          </form>
        </div>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white pt-2 pb-10 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <p className="text-center mb-6">{t('page.signUp.loginPageDescription')}</p>
            {/* eslint-disable-next-line */}
            <div onClick={() => dispatch(push('/login'))}>
              <BlueLineButton>{t('page.signUp.loginPage')}</BlueLineButton>
            </div>
          </div>
        </div>
      </div>
      <Modal email={email} companyName={companyName} />
    </div>
  );
};

export default SignUp;
