import { ErrorMessage } from './error';
import { User } from './user';
import { Company } from './company';

export const ADD_CONFIG = 'ADD_CONFIG';
export const ADD_CONFIG_FULFILLED = 'ADD_CONFIG_FULFILLED';
export const FETCH_CONFIG = 'FETCH_CONFIG';
export const FETCHING_FINISHED_CONFIG = 'FETCHING_FINISHED_CONFIG';
export const FAIL_CONFIG_REQUEST = 'FAIL_CONFIG_REQUEST';
export const CLEAR_FAIL_CONFIG_REQUEST = 'CLEAR_FAIL_CONFIG_REQUEST';

export interface Config {
  rootMenu: MenuItem;
  headerMenu: MenuItem[];
  pullDownMenu: PullDownMenuItem[];
  materialMenu: MaterialMenu;
  userMenu: UserMenu;
  articleMenu: ArticleItem[];
}

export interface MenuItem {
  text: string;
  path: string;
  icon: string;
}

export interface PullDownMenuItem {
  text: string;
  path: string;
  icon: string;
  target: string;
}

export interface MaterialMenu {
  sources: Source[];
  categories: Category[];
  tabs: Tab[];
  tabsInPills: Tab[];
}

export interface Source {
  name: string;
  value: string;
  label: string;
}

export interface Category {
  name: string;
  value: string;
}

export interface Tab {
  name: string;
  path: string;
  key: string;
  value: string;
}

export interface UserMenu {
  tabs: Tab[];
}

export interface ArticleItem {
  text: string;
  path: string;
  icon: string;
  navType: string;
}

export interface ConfigState {
  rootMenu: MenuItem;
  headerMenu: MenuItem[];
  pullDownMenu: MenuItem[];
  materialMenu: MaterialMenu;
  userMenu: UserMenu;
  articleMenu: ArticleItem[];
  isFetching: boolean;
  errors: ErrorMessage[];
}

export interface Me {
  user: User;
  config: Config;
  company: Company;
}

export interface AddConfigAction {
  type: typeof ADD_CONFIG;
  payload: {};
}

export interface AddConfigFulfilledAction {
  type: typeof ADD_CONFIG_FULFILLED;
  payload: { config: Config };
}

export interface FetchConfigAction {
  type: typeof FETCH_CONFIG;
  payload: {};
}

export interface FetchingFinishedConfigAction {
  type: typeof FETCHING_FINISHED_CONFIG;
  payload: {};
}

export interface FailConfigRequestAction {
  type: typeof FAIL_CONFIG_REQUEST;
  payload: { errors: ErrorMessage[] };
}

export interface ClearFailConfigRequestAction {
  type: typeof CLEAR_FAIL_CONFIG_REQUEST;
  payload: {};
}

export type ConfigActionTypes =
  AddConfigAction |
  AddConfigFulfilledAction |
  FetchConfigAction |
  FetchingFinishedConfigAction |
  FailConfigRequestAction |
  ClearFailConfigRequestAction;
