import * as types from '../../types/session';
import { Me } from '../../types/me';
import { ErrorMessage } from '../../types/error';
import { User } from '../../types/user';
import { Company } from '../../types/company';

export const addSession = (email: string, password: string): types.SessionActionTypes => (
  { type: types.ADD_SESSION, payload: { email, password } }
);

export const addSessionForEmailLink = (email: string): types.SessionActionTypes => (
  { type: types.ADD_SESSION_FOR_EMAIL_LINK, payload: { email } }
);

export const addSessionFulfilled = (me: Me): types.SessionActionTypes => (
  { type: types.ADD_SESSION_FULFILLED, payload: { me } }
);

export const signupSession = (
  email: string, companyName: string,
): types.SessionActionTypes => (
  { type: types.SIGNUP_SESSION, payload: { email, companyName } }
);

export const signupCompleteSession = (user: User, company: Company): types.SessionActionTypes => (
  { type: types.SIGNUP_COMPLETE_SESSION, payload: { user, company } }
);

export const clientSignupCompleteSession = (
  user: User, company: Company,
): types.SessionActionTypes => (
  { type: types.CLIENT_SIGNUP_COMPLETE_SESSION, payload: { user, company } }
);

export const updateUserSession = (user: User): types.SessionActionTypes => (
  { type: types.UPDATE_USER_SESSION, payload: { user } }
);

export const updateUserSessionFulfilled = (user: User): types.SessionActionTypes => (
  { type: types.UPDATE_USER_SESSION_FULFILLED, payload: { user } }
);

export const updateCompanySession = (company: Company): types.SessionActionTypes => (
  { type: types.UPDATE_COMPANY_SESSION, payload: { company } }
);

export const updateCompanySessionFulfilled = (company: Company): types.SessionActionTypes => (
  { type: types.UPDATE_COMPANY_SESSION_FULFILLED, payload: { company } }
);

export const updateEmailSession = (
  password: string, oldEmail: string, newEmail: string,
): types.SessionActionTypes => (
  { type: types.UPDATE_EMAIL_SESSION, payload: { password, oldEmail, newEmail } }
);

export const updateEmailFulfilledSession = (email: string): types.SessionActionTypes => (
  { type: types.UPDATE_EMAIL_SESSION_FULFILLED, payload: { email } }
);

export const updateNotificationConfigSession = (
  messageAll: boolean, messageMention: boolean,
): types.SessionActionTypes => (
  { type: types.NOTIFICATION_CONFIG_SESSION_FULFILLED, payload: { messageAll, messageMention } }
);

export const updateUserEmailSessionFulfilled = (user: User): types.SessionActionTypes => (
  { type: types.UPDATE_USER_SESSION_FULFILLED, payload: { user } }
);

export const deleteSession = (): types.SessionActionTypes => (
  { type: types.DELETE_SESSION, payload: {} }
);

export const deleteSessionFulfilled = (): types.SessionActionTypes => (
  { type: types.DELETE_SESSION_FULFILLED, payload: { } }
);

export const fetchingSession = (): types.SessionActionTypes => (
  { type: types.FETCHING_SESSION, payload: {} }
);

export const fetchingFinishedSession = (): types.SessionActionTypes => (
  { type: types.FETCHING_FINISHED_SESSION, payload: {} }
);

export const failSession = (errors: ErrorMessage[]): types.SessionActionTypes => (
  { type: types.FAIL_SESSION_REQUEST, payload: { errors } }
);

export const clearFailSession = (): types.SessionActionTypes => (
  { type: types.CLEAR_FAIL_SESSION_REQUEST, payload: {} }
);

export const emailAuthenticateSession = (): types.SessionActionTypes => (
  { type: types.EMAIL_AUTHENTICATED_SESSION, payload: {} }
);
