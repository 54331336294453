import * as types from '../../types/invitation';
import { ErrorMessage } from '../../types/error';

export const addInvitations = (query: string): types.InvitationActionTypes => (
  { type: types.ADD_INVITATIONS, payload: { query } }
);

export const addInvitationsFulfilled = (
  invitations: types.Invitation[],
  count: number,
): types.InvitationActionTypes => (
  { type: types.ADD_INVITATIONS_FULFILLED, payload: { invitations, count } }
);

export const addInvitation = (
  invitationForPost: types.InvitationForPost,
): types.InvitationActionTypes => (
  { type: types.ADD_INVITATION, payload: { invitationForPost } }
);

export const addInvitationFulfilled = (
  invitation: types.Invitation,
): types.InvitationActionTypes => (
  { type: types.ADD_INVITATION_FULFILLED, payload: { invitation } }
);

export const deleteInvitation = (invitedCompanyId: number): types.InvitationActionTypes => (
  { type: types.DELETE_INVITATION, payload: { invitedCompanyId } }
);

export const deleteInvitationFulfilled = (
  invitedCompanyId: number,
): types.InvitationActionTypes => (
  { type: types.DELETE_INVITATION_FULFILLED, payload: { invitedCompanyId } }
);

export const archiveInvitation = (invitedCompanyId: number): types.InvitationActionTypes => (
  { type: types.ARCHIVE_INVITATION, payload: { invitedCompanyId } }
);

export const archiveInvitationFulfilled = (
  invitedCompanyId: number,
): types.InvitationActionTypes => (
  { type: types.ARCHIVE_INVITATION_FULFILLED, payload: { invitedCompanyId } }
);

export const activateInvitation = (invitedOwnerUserId: number): types.InvitationActionTypes => (
  { type: types.ACTIVATE_INVITATION, payload: { invitedOwnerUserId } }
);

export const activateInvitationFulfilled = (
  invitedOwnerUserId: number,
): types.InvitationActionTypes => (
  { type: types.ACTIVATE_INVITATION_FULFILLED, payload: { invitedOwnerUserId } }
);

export const fetchingInvitation = (): types.InvitationActionTypes => (
  { type: types.FETCHING_INVITATION, payload: {} }
);

export const fetchingFinishedInvitation = (): types.InvitationActionTypes => (
  { type: types.FETCHING_FINISHED_INVITATION, payload: {} }
);

export const failInvitation = (errors: ErrorMessage[]): types.InvitationActionTypes => (
  { type: types.FAIL_INVITATION_REQUEST, payload: { errors } }
);

export const clearFailInvitation = (): types.InvitationActionTypes => (
  { type: types.CLEAR_FAIL_INVITATION_REQUEST, payload: {} }
);
