import React, {
  createContext, Dispatch, useContext, useEffect, useReducer, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { addConfigFulfilled } from '../redux/actions/config';
import { addSessionFulfilled, clearFailSession } from '../redux/actions/session';
import { findMe } from '../utils/api/me';

export interface State {
  isLoggedIn: boolean;
}

export type Action =
  { type: 'LOGIN' } |
  { type: 'LOGOUT' };

// Contextの型を用意
export interface IAuthContext {
  state: State;
  authDispatch: Dispatch<Action>;
}

// isLoggedInに変更
const AuthContext = createContext<IAuthContext>({
  state: { isLoggedIn: false },
} as IAuthContext);

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, isLoggedIn: true };
    case 'LOGOUT':
      return { ...state, isLoggedIn: false };
    default:
      return state;
  }
};

const initialState: State = {
  isLoggedIn: false,
};

const AuthProvider: React.FC = ({ children }): JSX.Element => {
  const dispatch = useDispatch();
  const [state, authDispatch] = useReducer(reducer, initialState);
  const value = { state, authDispatch };
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let unmounted = false;
    const f = async () => {
      try {
        if (!unmounted) {
          const me = await findMe();
          dispatch(addSessionFulfilled(me));
          dispatch(addConfigFulfilled(me.config));
          dispatch(clearFailSession());
          authDispatch({ type: 'LOGIN' });
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };
    f();
    return () => { unmounted = true; };
  }, []);

  if (isLoading) {
    return <>ロード中</>;
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAppContext = () => useContext(AuthContext);

export { AuthContext, AuthProvider };
