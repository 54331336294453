import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import sessionReducer from './session';
import notificationReducer from './notification';
import modalReducer from './modal';
import userReducer from './user';
import configReducer from './config';
import companyReducer from './company';
import invitationReducer from './invitation';
import articleReducer from './article';

const index = (history: History): any => combineReducers({
  session: sessionReducer,
  notification: notificationReducer,
  users: userReducer,
  config: configReducer,
  companies: companyReducer,
  invitations: invitationReducer,
  articles: articleReducer,
  modal: modalReducer,
  router: connectRouter(history),
});

export default index;
