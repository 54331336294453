import { ofType } from 'redux-observable';
import {
  catchError, concatMap, endWith, mergeMap, startWith,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import { LIKE_ARTICLE, UN_LIKE_ARTICLE } from '../../../types/article';
import * as types from '../../../types/article';
import { like, unLike } from '../../../utils/api/articles';
import {
  clearFailArticle,
  failArticle,
  fetchingFinishedArticle,
  fetchingArticle,
  updateArticleFulfilled,
} from '../../actions/article';
import { addNotification } from '../../actions/notification';

export const likeArticleEpic = (action$: any) =>
  action$.pipe(
    ofType(LIKE_ARTICLE),
    mergeMap((action: types.LikeArticleAction) =>
      from(like(action.payload.id)).pipe(
        concatMap((response) => of(
          updateArticleFulfilled(response.article),
          clearFailArticle(),
          addNotification({ message: '記事をお気に入りに登録しました', type: 'success' }),
        )),
        startWith(fetchingArticle()),
        catchError((errors) => of(failArticle(errors.response.data.errors))),
        endWith(fetchingFinishedArticle()),
      )),
  );

export const unLikeArticleEpic = (action$: any) =>
  action$.pipe(
    ofType(UN_LIKE_ARTICLE),
    mergeMap((action: types.UnLikeArticleAction) =>
      from(unLike(action.payload.id)).pipe(
        concatMap((response) => of(
          updateArticleFulfilled(response.article),
          clearFailArticle(),
          addNotification({ message: '記事をお気に入りから解除しました', type: 'success' }),
        )),
        startWith(fetchingArticle()),
        catchError((errors) => of(failArticle(errors.response.data.errors))),
        endWith(fetchingFinishedArticle()),
      )),
  );
