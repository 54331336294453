import { TypeEnum } from './enum';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export interface Modal {
  type: TypeEnum;
  title: string;
  message: string;
  buttons?: boolean;
}

export interface ModalState {
  type: TypeEnum;
  title: string;
  message: string;
  buttons?: boolean;
}

export interface OpenModal {
  type: typeof OPEN_MODAL;
  payload: { modal: Modal };
}

export interface CloseModal {
  type: typeof CLOSE_MODAL;
  payload: {};
}

export type ModalActionTypes = OpenModal | CloseModal;
