import {
  get, post, put, destroy as requestDestroy,
} from '../requests';
import { User } from '../../types/user';

export const findAll = async (query: string): Promise<any> => get<User[]>(`/users${query}`);
export const save = async (user: User): Promise<any> => post<User>('/users', { user });
export const update = async (user: User): Promise<any> => put<User>(`/users/${user.id}`, { user });
export const destroy = async (id: number): Promise<any> => requestDestroy<any>(`/users/${id}`);
export const activate = async (id: number): Promise<any> => put<any>(`/users/${id}/activate`, {});
export const archive = async (id: number): Promise<any> => requestDestroy<any>(`/users/${id}/archive`);
export const updateNotification = async (user: User): Promise<any> => put<User>(`/users/${user.id}/notification`, { user });
