import * as types from '../../types/notification';

const initialState: types.NotificationState = { message: '', type: '' };

export default function errorReducer(
  state = initialState, action: types.NotificationActionTypes,
): types.NotificationState {
  switch (action.type) {
    case types.ADD_NOTIFICATION: {
      return {
        message: action.payload.notification.message,
        type: action.payload.notification.type,
      };
    }
    case types.CLEAR_NOTIFICATION:
      return { message: '', type: '' };
    default:
      return state;
  }
}
