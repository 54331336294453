import React from 'react';

const BaseLabel: React.FC<React.LabelHTMLAttributes<HTMLLabelElement>> = (
  props,
) => {
  const { className } = props;
  return (
    <label
      {...props}
      className={className}
    />
  );
};

export default BaseLabel;
