import { combineEpics } from 'redux-observable';
import { addUserEpic } from './add';
import { addUsersEpic } from './add_all';
import { updateUserEpic } from './update';
import { deleteUserEpic } from './delete';
import { activateUserEpic } from './activate';
import { archiveUserEpic } from './archive';
import { archiveInvitationUserEpic } from './archive_invitation';
import { addInvitationUsersEpic } from './add_all_invitation';
import { addInvitationUserEpic } from './add_invitation';

export default combineEpics(
  addUserEpic,
  addUsersEpic,
  updateUserEpic,
  deleteUserEpic,
  activateUserEpic,
  archiveUserEpic,
  archiveInvitationUserEpic,
  addInvitationUsersEpic,
  addInvitationUserEpic,
);
