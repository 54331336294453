import React from 'react';
import { Route } from 'react-router-dom';

const PublicRoute: React.FC<any> = ({
  component: Component, session, ...rest
}: any): JSX.Element => (
  <Route
    {...rest}
    render={(props) => <Component {...props} />}
  />
);

export default PublicRoute;
