import { combineEpics } from 'redux-observable';
import { loginSessionEpic } from './login';
import { logoutSessionEpic } from './logout';
import { registerSessionEpic } from './register';
import { clientRegisterSessionEpic } from './client_register';
import { signupSessionEpic } from './signup';
import { updateCompanySessionEpic } from './update_company';
import { updateEmailSessionEpic } from './update_email';
import { updateUserSessionEpic } from './update_user';
import { loginForEmailLinkSessionEpic } from './login_for_email_link';

export default combineEpics(
  loginSessionEpic,
  logoutSessionEpic,
  registerSessionEpic,
  clientRegisterSessionEpic,
  signupSessionEpic,
  updateCompanySessionEpic,
  updateEmailSessionEpic,
  updateUserSessionEpic,
  loginForEmailLinkSessionEpic,
);
