import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import Logo from '../../assets/img/top_logo.png';
import { RootState } from '../../types/store';
import Error from '../../components/organisms/Error';
import Loading from '../../components/atoms/Loading';
import TextInput from '../../components/atoms/Inputs/TextInput';
import Label from '../../components/atoms/Labels/Label';
import Button from '../../components/atoms/Buttons/Button';
import { addSession, failSession } from '../../redux/actions/session';
import { persistor } from '../../redux/store';
import PublicLayout from '../../components/organisms/PublicLayout';
import TermsOfFooter from '../../components/atoms/Terms/TermsOfFooter';

const Login: React.FC = (): JSX.Element => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const dispatch = useDispatch();
  const getState = useSelector((state: RootState) => state);
  const { session } = getState;
  const { t } = useTranslation();

  const login = async (e: any) => {
    e.preventDefault();
    if (email === '' || password === '') {
      dispatch(failSession([{ message: t('validation.user.NotEmailAndPassword') }]));
      return;
    }
    dispatch(addSession(email, password));
  };

  useEffect(() => {
    persistor.flush().then(() => {
      persistor.purge();
    });
  }, []);

  return (
    <PublicLayout>
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <Error errors={session?.errors} />
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="sm:mx-auto sm:w-full sm:max-w-md mb-5">
              <img
                className="mx-auto w-auto"
                src={Logo}
                alt="Workflow"
              />
            </div>
            <form className="space-y-6" onSubmit={login}>
              <div>
                <Label label={t('attribute.user.email')} required={false} />
                <TextInput
                  id="email"
                  name="email"
                  value={email}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="email"
                  placeholder=""
                  required
                />
              </div>

              <div>
                <Label label={t('attribute.user.password')} required={false} />
                <TextInput
                  id="password"
                  name="password"
                  value={password}
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="current-password"
                  placeholder=""
                  required
                />
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember_me"
                    name="remember_me"
                    type="checkbox"
                    className="h-4 w-4 text-Navy-600 focus:ring-Navy-500 border-gray-300 rounded"
                  />
                  <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-900">
                    {t('link.rememberMe')}
                  </label>
                </div>

                <div className="text-sm">
                  <Link to="/help_for_login">
                    <a href="#" className="font-medium text-Navy-600 hover:text-Navy-800">
                      {t('pageTitle.helpForLogin')}
                    </a>
                  </Link>
                </div>
              </div>

              <div>
                { session.isFetching ? <Loading /> : <Button id="login">{t('action.login')}</Button> }
              </div>
            </form>
          </div>
        </div>
        <div className="flex items-center justify-center mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <p className="text-center">
            {t('page.helpForLogin.noAccount')}
          </p>
          <button
            className="cursor-pointer text-Navy-600 hover:text-Navy-800"
            onClick={() => dispatch(push('/signup'))}
          >
            {t('page.helpForLogin.here')}
          </button>
        </div>
        <TermsOfFooter />
      </div>
    </PublicLayout>
  );
};

export default Login;
