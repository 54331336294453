import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const today = new Date();

export const FooterTerms: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="text-center mt-5">
      <Link
        to="/terms/service"
        className="font-medium text-Navy-600 hover:text-Navy-800"
      >
        {t('attribute.term.service')}
      </Link>
      |
      <Link
        to="/terms/privacy"
        className="font-medium text-Navy-600 hover:text-Navy-800"
      >
        {t('attribute.term.privacy')}
      </Link>
      |
      <a href="https://app.hrbase.jp/pages/law" className="font-medium text-Navy-600 hover:text-Navy-800">
        {t('attribute.term.commercialTransaction')}
      </a>
      <p>
        {t('companyInfo.copyright')}
        -
        {
        today.getFullYear()
        }
        {t('companyInfo.name')}
      </p>
    </div>
  );
};

export default FooterTerms;
