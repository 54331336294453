import React from 'react';
import Logo from '../../../assets/img/top_logo.png';

interface Props {
  title: string;
}

const CompanyLogo: React.FC<Props> = (props) => {
  const { title } = props;

  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md mb-5">
      <img
        className="mx-auto w-72"
        src={Logo}
        alt="Workflow"
      />
      <p className="text-center font-bold mt-3">{title}</p>
    </div>
  );
};

export default CompanyLogo;
