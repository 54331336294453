import React, { Fragment, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../types/store';
import { closeModal } from '../../redux/actions/modal';
import Button from '../atoms/Buttons/Button';
import SuccessIcon from '../atoms/Icons/SuccessIcon';
import FailureIcon from '../atoms/Icons/FailureIcon';

const ModalLayout: React.FC = ({ children }): JSX.Element => {
  const dispatch = useDispatch();
  const { modal } = useSelector((state: RootState) => state);
  const { t } = useTranslation();
  const inputRef = useRef<HTMLButtonElement>(null);

  const handleClose = () => dispatch(closeModal());

  useEffect(() => {
    const focusOnBtn = async () => {
      const node = await inputRef.current;
      if (node) {
        node.focus();
      }
    };
    focusOnBtn();
  }, [modal]);

  return (
    <>
      { modal.title
      && (
        <Transition.Root show as={Fragment}>
          <Dialog as="div" static className="fixed z-30 inset-0 overflow-y-auto" open onClose={handleClose}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle md:max-w-lg sm:max-w-sm sm:w-full sm:p-6">
                  <div>
                    { modal.type === 'success' && <SuccessIcon /> }
                    { modal.type === 'failure' && <FailureIcon /> }
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 break-all">
                        { modal.title }
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          { modal.message }
                        </p>
                        { children }
                      </div>
                    </div>
                  </div>
                  { (modal.buttons || modal.buttons === undefined)
                    && (
                      <div className="mt-5 sm:mt-6">
                        <Button ref={inputRef} type="button" onClick={handleClose}>{t('action.close')}</Button>
                      </div>
                    ) }
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  );
};

export default ModalLayout;
