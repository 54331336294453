import { ofType } from 'redux-observable';
import {
  catchError, concatMap, endWith, mergeMap, startWith,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ADD_ARTICLES_FOR_SENT, ADD_ARTICLES_FOR_RECEIVE } from '../../../types/article';
import * as types from '../../../types/article';
import { findAllForSent, findAllForReceive } from '../../../utils/api/articles';
import {
  addArticlesFulfilled,
  clearFailArticle,
  failArticle,
  fetchingFinishedArticle,
  fetchingArticle,
} from '../../actions/article';

export const addArticlesEpicForSent = (action$: any) =>
  action$.pipe(
    ofType(ADD_ARTICLES_FOR_SENT),
    mergeMap((action: types.AddArticlesActionForSent) =>
      from(findAllForSent(action.payload.query)).pipe(
        concatMap((response) => of(
          addArticlesFulfilled(response.articles, response.count),
          clearFailArticle(),
        )),
        startWith(fetchingArticle()),
        catchError((errors) => of(failArticle(errors.response.data.errors))),
        endWith(fetchingFinishedArticle()),
      )),
  );

export const addArticlesEpicForReceive = (action$: any) =>
  action$.pipe(
    ofType(ADD_ARTICLES_FOR_RECEIVE),
    mergeMap((action: types.AddArticlesActionForReceive) =>
      from(findAllForReceive(action.payload.query)).pipe(
        concatMap((response) => of(
          addArticlesFulfilled(response.articles, response.count),
          clearFailArticle(),
        )),
        startWith(fetchingArticle()),
        catchError((errors) => of(failArticle(errors.response.data.errors))),
        endWith(fetchingFinishedArticle()),
      )),
  );
