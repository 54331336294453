import * as types from '../../types/user';

const initialState: types.UserState = {
  isFetching: false,
  errors: [],
  data: [],
  count: 0,
} as types.UserState;

export default function userReducer(
  state = initialState, action: types.UserActionTypes,
): types.UserState {
  switch (action.type) {
    case types.ADD_USERS_FULFILLED: {
      return { ...state, data: action.payload.users, count: action.payload.count };
    }
    case types.ADD_USER_FULFILLED: {
      const { user } = action.payload;
      const clone = state.data;
      clone.push(user);
      return { ...state, data: clone };
    }
    case types.UPDATE_USER_FULFILLED: {
      const { user } = action.payload;
      const clone = state.data;
      const index = clone.findIndex((obj: any) => obj.id === user.id);
      if (index !== -1) clone[index] = user;
      return { ...state, data: clone };
    }
    case types.DELETE_USER_FULFILLED: {
      const { id } = action.payload;
      return { ...state, data: state.data.filter((item) => item.id !== Number(id)) };
    }
    case types.ACTIVATE_USER_FULFILLED: {
      const { id } = action.payload;
      return { ...state, data: state.data.filter((item) => item.id !== Number(id)) };
    }
    case types.ADD_INVITATION_USERS_FULFILLED: {
      return {
        ...state,
        data: action.payload.users,
        company: action.payload.company,
        count: action.payload.count,
      };
    }
    case types.FETCHING_USER: {
      return { ...state, isFetching: true };
    }
    case types.FETCHING_FINISHED_USER: {
      return { ...state, isFetching: false };
    }
    case types.CLEAR_FAIL_USER_REQUEST: {
      return { ...state, errors: [] };
    }
    case types.FAIL_USER_REQUEST: {
      const { errors } = action.payload;
      return { ...state, errors };
    }
    default:
      return state;
  }
}
