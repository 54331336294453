import thunk from 'redux-thunk';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router/immutable';
import { createEpicMiddleware } from 'redux-observable';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import Reducers from '../reducers';
import history from '../../utils/history';
import { rootEpic } from '../epics';

const epicMiddleware = createEpicMiddleware();
const nodeEnv = process.env.NODE_ENV;

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, Reducers(history));

// reduxの状態を確認するため
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const composeEnhancers = (nodeEnv !== 'production' && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(
  persistedReducer,
  composeEnhancers(
    // middlewares
    applyMiddleware<any>(
      epicMiddleware, // Stream管理のMiddleware
      routerMiddleware(history), // Route管理のMiddleware
      thunk, // 非同期actinoを許可するためのMiddleware
    ),
  ),
);

epicMiddleware.run(rootEpic);

export const persistor = persistStore(store);
export default store;
