import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import {
  catchError, concatMap, mergeMap, startWith,
} from 'rxjs/operators';
import { UPDATE_USER } from '../../../types/user';
import * as types from '../../../types/user';
import { update } from '../../../utils/api/users';
import {
  clearFailUser, failUser, fetchingFinishedUser, fetchingUser, updateUserFulfilled,
} from '../../actions/user';
import { openModal } from '../../actions/modal';

export const updateUserEpic = (action$: any) =>
  action$.pipe(
    ofType(UPDATE_USER),
    mergeMap((action: types.UpdateUserAction) =>
      from(update(action.payload.user)).pipe(
        concatMap((response) => of(
          updateUserFulfilled(response),
          fetchingFinishedUser(),
          clearFailUser(),
          // TODO: i18n対応。reduxで使うとハマる
          openModal({
            title: 'ユーザーを更新しました。', message: '', type: 'success', buttons: true,
          }),
        )),
        startWith(fetchingUser()),
        catchError((errors) => of(
          failUser(errors.response.data.errors),
          fetchingFinishedUser(),
        )),
      )),
  );
