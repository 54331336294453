import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const isDevelopment: boolean = process.env.NODE_ENV === 'development';

if (isDevelopment) {
  firebase.initializeApp({
    projectId: 'test',
    appId: 'test',
    apiKey: 'test',
  });
  firebase.auth().useEmulator('http://localhost:9099');
} else {
  const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };
  firebase.initializeApp(config);
}

// TODO もしメール認証が終えていなければ、メール再送する処理を実装する。
export const login = async (
  email: string, password: string,
): Promise<firebase.auth.UserCredential> =>
  firebase.auth().signInWithEmailAndPassword(email, password);

export const createUserWithEmailAndPassword = async (
  email: string, password: string,
): Promise<firebase.auth.UserCredential> =>
  firebase.auth().createUserWithEmailAndPassword(email, password);

export const sendVerification = (): Promise<void> | unknown =>
  firebase.auth().currentUser?.sendEmailVerification({
    url: `${process.env.REACT_APP_PATH}/pro/owner_steps`,
    handleCodeInApp: false,
  });

export const isSignInLink = (): boolean =>
  firebase.auth().isSignInWithEmailLink(window.location.href);

export const makeLoginAfterInvitation = async (
  email: string,
): Promise<firebase.auth.UserCredential> =>
  firebase.auth().signInWithEmailLink(email, window.location.href);

export const logout = async (): Promise<any> => firebase.auth().signOut();

export const currentUser = (): Promise<firebase.User> =>
  new Promise((resolve, reject) =>
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        return resolve(user);
      }
      return reject();
    }, () => reject()));

export const refreshToken = async (): Promise<string> => {
  const user = await currentUser();
  const token = await user.getIdToken(true);
  return token;
};

// role/kindにかかわらず利用するためにemailを引数に割り当て
export const sendPasswordResetEmail = async (email: string): Promise<void> =>
  firebase.auth().sendPasswordResetEmail(email, {
    url: `${process.env.REACT_APP_PATH}`,
    handleCodeInApp: false,
  });

// email変更の関数。新旧のemailが必須
export const changeEmail = async (
  password: string, oldEmail: string, newEmail: string,
): Promise<any> =>
  new Promise((resolve, reject) =>
    firebase.auth().signInWithEmailAndPassword(oldEmail, password)
      .then((cred) => {
        if (cred.user === null) {
          return reject(cred);
        }
        cred.user.updateEmail(newEmail);
        return resolve(true);
      }).catch((e) => reject(e)));

export const firebaseError = (code: string): string => {
  switch (code) {
    case 'auth/email-already-in-use':
      return 'このメールアドレスは使用されています';
    case 'auth/invalid-email':
      return 'メールアドレスの形式が正しくありません';
    case 'auth/user-disabled':
      return 'サービスの利用が停止されています';
    case 'auth/user-not-found':
      return 'メールアドレスまたはパスワードが違います';
    case 'auth/user-mismatch':
      return 'メールアドレスまたはパスワードが違います';
    case 'auth/weak-password':
      return 'パスワードは英数字混在の8文字以上にしてください';
    case 'auth/wrong-password':
      return 'メールアドレスまたはパスワードが違います';
    case 'auth/popup-blocked':
      return '認証ポップアップがブロックされました。ポップアップブロックをご利用の場合は設定を解除してください';
    case 'auth/operation-not-supported-in-this-environment':
    case 'auth/auth-domain-config-required':
    case 'auth/operation-not-allowed':
    case 'auth/unauthorized-domain':
      return '現在この認証方法はご利用頂けません';
    case 'auth/requires-recent-login':
      return '認証の有効期限が切れています';
    case 'auth/invalid-action-code':
      return 'URL認証のメールの有効期限が切れています。メール送信者に再度の招待をご依頼ください。';
    case 'auth/too-many-requests':
      return 'パスワードを数回間違えました。時間をおいてまたログインし直すか、パスワードを変更してください。';
    default:
      return 'エラーが発生しました';
  }
};
