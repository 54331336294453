import { ofType } from 'redux-observable';
import {
  catchError, concatMap, mergeMap, startWith,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ADD_INVITATION_USER } from '../../../types/user';
import * as types from '../../../types/user';
import { createClient } from '../../../utils/api/invitations';
import {
  addUserFulfilled, clearFailUser, failUser, fetchingFinishedUser, fetchingUser,
} from '../../actions/user';
import { closeModal, openModal } from '../../actions/modal';

export const addInvitationUserEpic = (action$: any) =>
  action$.pipe(
    ofType(ADD_INVITATION_USER),
    mergeMap((action: types.AddInvitationUserAction) =>
      from(createClient(action.payload.invitedCompanyid, action.payload.user)).pipe(
        concatMap((response) => of(
          addUserFulfilled(response.user),
          fetchingFinishedUser(),
          clearFailUser(),
          closeModal(),
          openModal({
            title: '招待メールを送信しました', message: '認証するとHRbase PROをご利用いただけます。', type: 'success', buttons: true,
          }),
        )),
        startWith(fetchingUser()),
        catchError((errors) => of(
          failUser(errors.response.data.errors),
          fetchingFinishedUser(),
        )),
      )),
  );
