import * as types from '../../types/modal';

const initialState: types.ModalState = {
  type: 'none',
  title: '',
  message: '',
  buttons: true,
};

export default function modalReducer(
  state = initialState, action: types.ModalActionTypes,
): types.ModalState {
  switch (action.type) {
    case types.OPEN_MODAL: {
      const { modal } = action.payload;
      return {
        type: modal.type,
        title: modal.title,
        message: modal.message,
        buttons: modal.buttons,
      };
    }
    case types.CLOSE_MODAL: {
      return {
        type: 'none', title: '', message: '', buttons: true,
      };
    }
    default:
      return state;
  }
}
