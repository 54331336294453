import { ofType } from 'redux-observable';
import {
  catchError, concatMap, mergeMap, startWith,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ADD_USERS } from '../../../types/user';
import * as types from '../../../types/user';
import { findAll } from '../../../utils/api/users';
import {
  addUsersFulfilled, clearFailUser, failUser, fetchingFinishedUser, fetchingUser,
} from '../../actions/user';

export const addUsersEpic = (action$: any) =>
  action$.pipe(
    ofType(ADD_USERS),
    mergeMap((action: types.AddUsersAction) =>
      from(findAll(action.payload.query)).pipe(
        concatMap((response) => of(
          addUsersFulfilled(response.users, response.count),
          fetchingFinishedUser(),
          clearFailUser(),
        )),
        startWith(fetchingUser()),
        catchError((errors) => of(
          failUser(errors.response.data.errors),
          fetchingFinishedUser(),
        )),
      )),
  );
