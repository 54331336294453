import React from 'react';
import {
  CreditCardIcon, OfficeBuildingIcon, UserCircleIcon, UserGroupIcon,
  MailIcon, LockClosedIcon, ClipboardCopyIcon, DocumentIcon, InformationCircleIcon,
  PaperClipIcon, BookOpenIcon, CheckIcon, TrashIcon, DocumentSearchIcon, BellIcon,
  ExclamationIcon, PencilIcon,
} from '@heroicons/react/outline';

interface Props {
  icon: string;
  className: string;
  aria: boolean;
}

// HACK:分岐に使う値（今はpath）どうするか
const Icon: React.FC<Props> = (props): JSX.Element => {
  const { icon, className, aria } = props;

  const strToIcon = () => {
    switch (icon) {
      case 'OfficeBuildingIcon':
        return <OfficeBuildingIcon className={className} aria-hidden={aria} />;
      case 'UserGroupIcon':
        return <UserGroupIcon className={className} aria-hidden={aria} />;
      case 'CreditCardIcon':
        return <CreditCardIcon className={className} aria-hidden={aria} />;
      case 'UserCircleIcon':
        return <UserCircleIcon className={className} aria-hidden={aria} />;
      case 'MailIcon':
        return <MailIcon className={className} aria-hidden={aria} />;
      case 'LockClosedIcon':
        return <LockClosedIcon className={className} aria-hidden={aria} />;
      case 'ClipboardCopyIcon':
        return <ClipboardCopyIcon className={className} aria-hidden={aria} />;
      case 'DocumentIcon':
        return <DocumentIcon className={className} aria-hidden={aria} />;
      case 'DocumentSearchIcon':
        return <DocumentSearchIcon className={className} aria-hidden={aria} />;
      case 'InformationCircleIcon':
        return <InformationCircleIcon className={className} aria-hidden={aria} />;
      case 'PaperClipIcon':
        return <PaperClipIcon className={className} aria-hidden={aria} />;
      case 'BookOpenIcon':
        return <BookOpenIcon className={className} aria-hidden={aria} />;
      case 'CheckIcon':
        return <CheckIcon className={className} aria-hidden={aria} />;
      case 'TrashIcon':
        return <TrashIcon className={className} aria-hidden={aria} />;
      case 'BellIcon':
        return <BellIcon className={className} aria-hidden={aria} />;
      case 'ExclamationIcon':
        return <ExclamationIcon className={className} aria-hidden={aria} />;
      case 'PencilIcon':
        return <PencilIcon className={className} aria-hidden={aria} />;
      default:
        return null;
    }
  };
  return <>{strToIcon()}</>;
};

export default Icon;
