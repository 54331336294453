import { ofType } from 'redux-observable';
import {
  catchError, concatMap, mergeMap, startWith,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ADD_USER } from '../../../types/user';
import * as types from '../../../types/user';
import { save } from '../../../utils/api/users';
import {
  addUserFulfilled, clearFailUser, failUser, fetchingFinishedUser, fetchingUser,
} from '../../actions/user';
import { closeModal, openModal } from '../../actions/modal';

export const addUserEpic = (action$: any) =>
  action$.pipe(
    ofType(ADD_USER),
    mergeMap((action: types.AddUserAction) =>
      from(save(action.payload.user)).pipe(
        concatMap((response) => of(
          addUserFulfilled(response.user),
          fetchingFinishedUser(),
          clearFailUser(),
          closeModal(),
          // TODO: i18n対応。reduxで使うとハマる
          openModal({
            title: '招待メールを送信しました', message: '認証するとHRbase PROをご利用いただけます。', type: 'success', buttons: true,
          }),
        )),
        startWith(fetchingUser()),
        catchError((errors) => of(
          failUser(errors.response.data.errors),
          fetchingFinishedUser(),
        )),
      )),
  );
