/* eslint-disable max-len */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

// import the components
import Login from '../pages/auth/Login';
import SignUp from '../pages/auth/SignUp';
import NotFound from '../pages/NotFound';
import PublicRoute from './PublicRoute';
import VerifyEmail from '../pages/auth/VerifyEmail';

const Routes = (): JSX.Element => (
  <Switch>
    <PublicRoute exact path="/login" component={Login} />
    <PublicRoute exact path="/signup" component={SignUp} />
    <PublicRoute exact path="/verify_email" component={VerifyEmail} />
    <Route path="*" component={NotFound} />
  </Switch>
);

export default Routes;
