import { useLocation } from 'react-router-dom';
import { parseISO, format } from 'date-fns';
import { useSelector } from 'react-redux';
import { RootState } from '../types/store';
import { User } from '../types/user';
import { Company } from '../types/company';
import { File } from '../types/file';

export const classNames = (...classes: string[]): string => classes.filter(Boolean).join(' ');

export const checkCurrentPath = (path: string): boolean => {
  const pathName = useLocation().pathname;
  return path.includes(pathName.split('/')[2]);
};

export const parseDate = (date: string): string => {
  if (date === '' || date === 'undefined') {
    return '';
  }
  return format(parseISO(date), 'yyyy/MM/dd');
};

export const parseDateTime = (dateTime: string): string => {
  if (dateTime === '' || dateTime === 'undefined') {
    return '';
  }
  return format(parseISO(dateTime), 'yyyy/MM/dd HH:mm');
};

export const removeHtml = (str: string | undefined): string => {
  if (str !== undefined) { return str.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, ''); }
  return '';
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const checkAuth = () => {
  const { session } = useSelector((state: RootState) => state);

  const isMine = (currentUserId: number | undefined) => (session?.user?.id === currentUserId);

  return { isMine };
};

export const isSendable = (user: User): boolean => {
  if (parseDateTime(`${user?.deletedAt}`) === '' && user?.status === 'InProcessOfAuthentication') {
    return true;
  } else {
    return false;
  }
};

export const isPro = (company: Company): boolean => company.contractType === 'Pro';

export const isNull = (value: any): boolean =>
  value === null || value === undefined || !value.length;

export const isEmpty = (value: any): boolean =>
  value === null || value === undefined || value === '' || !value.length;

export const isPdf = (fileName: string | undefined): boolean =>
  fileName?.match(/.pdf/) != null;

export const validateFileSize = (e: React.FormEvent<HTMLFormElement>,
  filesData: File[] | undefined, maxSize: number, success: any, failure: any) => {
  e.preventDefault();
  const initialTotalSize = 0;
  const totalSize = filesData?.reduce((sum, newFileData) => {
    if (newFileData?.size === undefined) {
      return sum;
    } else {
      return sum + newFileData?.size;
    }
  }, initialTotalSize);
  if (isNull(filesData) || (totalSize !== undefined && totalSize <= maxSize)) {
    try {
      success();
    } catch (error) {
      console.log(error);
    }
  } else {
    failure();
  }
};
