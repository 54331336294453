// 新規登録のepic
import { ofType } from 'redux-observable';
import {
  catchError, concatMap, endWith, mergeMap, startWith,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import { SIGNUP_SESSION } from '../../../types/session';
import * as types from '../../../types/session';
import { signUp } from '../../../utils/api/session';
import { openModal } from '../../actions/modal';
import {
  clearFailSession, failSession, fetchingFinishedSession, fetchingSession,
} from '../../actions/session';

export const signupSessionEpic = (action$: any) =>
  action$.pipe(
    ofType(SIGNUP_SESSION),
    mergeMap((action: types.SignupSessionAction) =>
      from(signUp(action.payload.email, action.payload.companyName)).pipe(
        concatMap((response) => of(
          // TODO: i18n対応。reduxで使うとハマる
          (response === undefined
            ? openModal({ type: 'success', title: `${action.payload.email}へ認証メールを再送信しました`, message: '' })
            : openModal({ type: 'success', title: `${action.payload.email}へ認証メールを送信しました`, message: '72時間以内にURLをクリックし、アカウントを認証してください。' })),
          clearFailSession(),
        )),
        startWith(fetchingSession()),
        catchError((errors) => of(
          // TODO: FIREBASEのエラーを直接返すのではなく、API側でエラーを実装する
          failSession(errors.response.data.errors.map((error:any) => {
            if (error.message.includes('malformed email string')) {
              return { message: '不正な文字列です' };
            } else if (error.message === 'password must be a string at least 6 characters long') {
              return { message: 'パスワードは半角英数字8文字以上にしてください' };
            } else {
              // FIREBASEのエラー文字列をJSONに変換して条件分岐させている
              const errorJson = JSON.parse(error?.message?.split('body:')[1]);
              if (errorJson.error.message === 'EMAIL_EXISTS') {
                return { message: '存在するユーザーです' };
              } else if (errorJson.error.message === 'INVALID_EMAIL') {
                return { message: 'メールの形式が不正です' };
              } else {
                return { message: error.message };
              }
            }
          })),
        )),
        endWith(fetchingFinishedSession()),
      )),
  );
