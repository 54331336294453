import * as types from '../../types/me';
import { Config } from '../../types/me';
import { ErrorMessage } from '../../types/error';

export const addConfig = (): types.ConfigActionTypes => (
  { type: types.ADD_CONFIG, payload: {} }
);

export const addConfigFulfilled = (config: Config): types.ConfigActionTypes => (
  { type: types.ADD_CONFIG_FULFILLED, payload: { config } }
);

export const fetchConfig = (): types.ConfigActionTypes => (
  { type: types.FETCH_CONFIG, payload: {} }
);

export const fetchingFinishedConfig = (): types.ConfigActionTypes => (
  { type: types.FETCHING_FINISHED_CONFIG, payload: {} }
);

export const failConfig = (errors: ErrorMessage[]): types.ConfigActionTypes => (
  { type: types.FAIL_CONFIG_REQUEST, payload: { errors } }
);

export const clearFailConfig = (): types.ConfigActionTypes => (
  { type: types.CLEAR_FAIL_CONFIG_REQUEST, payload: {} }
);
