// me更新時のepic
import { ofType, StateObservable } from 'redux-observable';
import { push } from 'connected-react-router';
import {
  catchError, concatMap, endWith, mergeMap, startWith,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import { UPDATE_USER_SESSION } from '../../../types/session';
import * as types from '../../../types/session';
import { RootState } from '../../../types/store';
import { update } from '../../../utils/api/users';
import {
  clearFailSession,
  failSession,
  fetchingFinishedSession,
  fetchingSession,
  updateUserSessionFulfilled,
} from '../../actions/session';
import { addNotification } from '../../actions/notification';

export const updateUserSessionEpic = (action$: any, state$: StateObservable<RootState>) =>
  action$.pipe(
    ofType(UPDATE_USER_SESSION),
    mergeMap((action: types.UpdateUserSessionAction) =>
      from(update(action.payload.user)).pipe(
        concatMap((response) => of(
          updateUserSessionFulfilled(response.user),
          clearFailSession(),
          // TODO: i18n対応。reduxで使うとハマる
          (action.payload.user.password === undefined
            ? push(`/${state$.value.session.company.contractType.toLowerCase()}/me`)
            : push('/login')),
          (action.payload.user.password === undefined
            ? addNotification({ message: '更新しました。', type: 'success' })
            : addNotification({ message: 'パスワードを変更しました。再度ログインしてください', type: 'success' })),
        )),
        startWith(fetchingSession()),
        catchError((errors) => of(failSession(errors.response.data.errors))),
        endWith(fetchingFinishedSession()),
      )),
  );
