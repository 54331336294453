import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import {
  catchError, concatMap, endWith, mergeMap, startWith,
} from 'rxjs/operators';
import { DELETE_SESSION } from '../../../types/session';
import { sessionDelete } from '../../../utils/api/session';
import {
  clearFailSession,
  deleteSessionFulfilled,
  failSession,
  fetchingFinishedSession,
  fetchingSession,
} from '../../actions/session';

export const logoutSessionEpic = (action$: any) =>
  action$.pipe(
    ofType(DELETE_SESSION),
    mergeMap(() =>
      from(sessionDelete()).pipe(
        // eslint-disable-next-line no-return-assign
        concatMap(() => of(
          deleteSessionFulfilled(),
          clearFailSession(),
          window.location.href = `${process.env.REACT_APP_NEXT_PATH}/login?old=true`,
        )),
        startWith(fetchingSession()),
        catchError((errors) => of(
          failSession(errors.response.data.errors),
          fetchingFinishedSession(),
        )),
        endWith(fetchingFinishedSession()),
      )),
  );
