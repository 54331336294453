import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CompanyLogo from '../../components/atoms/Logos/CompanyLogo';
import TextInput from '../../components/atoms/Inputs/TextInput';
import Label from '../../components/atoms/Labels/Label';
import Button from '../../components/atoms/Buttons/Button';
import ModalLayout from '../../components/organisms/ModalLayout';
import Error from '../../components/organisms/Error';

import { isSignInLink } from '../../utils/api/firebase';
import { getLoginEmail } from '../../utils/localstorage';
import { addSessionForEmailLink } from '../../redux/actions/session';
import { persistor } from '../../redux/store';
import { RootState } from '../../types/store';
import Loading from '../../components/atoms/Loading';

const VerifyEmail: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { session } = useSelector((state: RootState) => state);
  const [email, setEmail] = useState<string>(getLoginEmail());

  const verifyEmail = () => {
    if (email !== '') {
      dispatch(addSessionForEmailLink(email));
    }
  };

  const hundleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    verifyEmail();
  };

  useEffect(() => {
    if (isSignInLink()) {
      persistor.flush().then(() => {
        persistor.purge();
      });
      verifyEmail();
    }
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <CompanyLogo title={t('pageTitle.verifyEmail')} />
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <Error errors={session?.errors} />
          <form className="space-y-6">
            <div>
              <Label label={t('attribute.user.email')} required={false} />
              <TextInput
                id="email"
                name="email"
                value={email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
                placeholder=""
                required
              />
            </div>
            <div className="flex mb-4">
              <div className="mx-4 w-full">
                { session.isFetching ? <Loading /> : <Button onClick={(e) => hundleSubmit(e)}>{t('action.login')}</Button>}
              </div>
            </div>
          </form>
        </div>
      </div>
      <ModalLayout />
    </div>
  );
};

export default VerifyEmail;
