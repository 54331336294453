import * as types from '../../types/session';
import { user as initialUser, company as initialCompany } from './initial_states';

const initialState: types.SessionState = {
  isLoggedIn: false,
  isFetching: false,
  isEmailAuthenticated: false,
  errors: [],
  user: initialUser,
  company: initialCompany,
} as types.SessionState;

export default function sessionReducer(
  state = initialState, action: types.SessionActionTypes,
): types.SessionState {
  switch (action.type) {
    case types.ADD_SESSION_FULFILLED: {
      const { me } = action.payload;
      return {
        ...state,
        isLoggedIn: true,
        user: me === undefined ? initialUser : me.user,
        company: me === undefined ? initialCompany : me.company,
      };
    }
    case types.UPDATE_USER_SESSION_FULFILLED: {
      const { user } = action.payload;
      return { ...state, user };
    }
    case types.UPDATE_COMPANY_SESSION_FULFILLED: {
      const { company } = action.payload;
      return { ...state, company };
    }
    case types.UPDATE_EMAIL_SESSION_FULFILLED: {
      const { email } = action.payload;
      return {
        ...state,
        user: { ...state.user, email },
      };
    }
    case types.DELETE_SESSION_FULFILLED: {
      return { ...initialState };
    }
    case types.FETCHING_SESSION: {
      return { ...state, isFetching: true };
    }
    case types.FETCHING_FINISHED_SESSION: {
      return { ...state, isFetching: false };
    }
    case types.FAIL_SESSION_REQUEST: {
      const { errors } = action.payload;
      return { ...state, errors };
    }
    case types.CLEAR_FAIL_SESSION_REQUEST: {
      return { ...state, errors: [] };
    }
    case types.EMAIL_AUTHENTICATED_SESSION: {
      return { ...state, isEmailAuthenticated: true };
    }
    case types.NOTIFICATION_CONFIG_SESSION_FULFILLED: {
      const { messageAll } = action.payload;
      const { messageMention } = action.payload;
      return {
        ...state,
        user: { ...state.user, messageAll, messageMention },
      };
    }
    default:
      return state;
  }
}
