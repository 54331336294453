import { post, destroy } from '../requests';
import { User } from '../../types/user';
import { Company } from '../../types/company';
import { Me } from '../../types/me';

export const sessionPost = async (token: string | undefined): Promise<any> => post('/session', {}, token);

export const sessionDelete = async (): Promise<any> => destroy('/session');

export const sendSignInLink = async (id: number): Promise<any> =>
  post('/signin/send_singin_link', { id });

export const signUp = async (email: string, name: string): Promise<any> =>
  post('/signup', { user: { email }, company: { name } });

export const signUpComplete = async (user: User, company: Company): Promise<any> =>
  post<Me>('/signup/complete', { user, company });
