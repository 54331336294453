// ログインのepic
import { ofType } from 'redux-observable';
import {
  catchError, concatMap, endWith, mergeMap, startWith,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ADD_SESSION_FOR_EMAIL_LINK } from '../../../types/session';
import * as types from '../../../types/session';
import {
  addSessionFulfilled,
  clearFailSession,
  failSession,
  fetchingFinishedSession,
  fetchingSession,
} from '../../actions/session';
import { addConfigFulfilled } from '../../actions/config';
import { firebaseError, makeLoginAfterInvitation } from '../../../utils/api/firebase';
import { Me } from '../../../types/me';
import { removeLoginEmail } from '../../../utils/localstorage';
import { sessionPost } from '../../../utils/api/session';
import { findMe } from '../../../utils/api/me';

const handleLoginForEmailLink = async (email: string): Promise<Me> => {
  try {
    const result = await makeLoginAfterInvitation(email);
    const token = await result.user?.getIdToken() || '';
    await sessionPost(token);

    removeLoginEmail();
    return await findMe();
  } catch (e) {
    return Promise.reject(e);
  }
};

const redirectToLoginPage = () => {
  window.location.href = `${process.env.REACT_APP_NEXT_PATH}/login`;
};

export const loginForEmailLinkSessionEpic = (action$: any) =>
  action$.pipe(
    ofType(ADD_SESSION_FOR_EMAIL_LINK),
    mergeMap((action: types.AddSessionForEmailLinkAction) =>
      from(handleLoginForEmailLink(action.payload.email)).pipe(
        concatMap((response) => of(
          addSessionFulfilled(response),
          addConfigFulfilled(response?.config),
          clearFailSession(),
          redirectToLoginPage(),
        )),
        startWith(fetchingSession()),
        catchError((error) => of(
          (!error.code && typeof error === 'object'
            ? failSession([{ message: 'メール認証がまだ完了してません。登録されたメールアドレスに認証メールを再送信しました。' }])
            : failSession([{ message: firebaseError(error.code) }])),
        )),
        endWith(fetchingFinishedSession()),
      )),
  );
