import * as types from '../../types/notification';

export const addNotification = (
  notification: types.Notification,
): types.NotificationActionTypes => (
  { type: types.ADD_NOTIFICATION, payload: { notification } }
);

export const clearNotification = (): types.NotificationActionTypes => (
  { type: types.CLEAR_NOTIFICATION, payload: {} }
);
