import * as types from '../../types/company';

const initialState: types.CompanyState = {
  isFetching: false,
  errors: [],
  data: [],
} as types.CompanyState;

export default function companyReducer(
  state = initialState, action: types.CompanyActionTypes,
): types.CompanyState {
  switch (action.type) {
    case types.ADD_COMPANY_FULFILLED: {
      const { company } = action.payload;
      const clone = state.data;
      clone.push(company);
      return { ...state, data: clone };
    }
    case types.UPDATE_COMPANY_FULFILLED: {
      const { company } = action.payload;
      const clone = state.data;
      const index = clone.findIndex((obj: any) => obj.id === company.id);
      if (index !== -1) clone[index] = company;
      return { ...state, data: clone };
    }
    case types.DELETE_COMPANY_FULFILLED: {
      const { id } = action.payload;
      const clone = state.data;
      const index = clone.findIndex((obj: any) => obj.id === id);
      if (index !== -1) clone.splice(index, 1);
      return { ...state, data: clone };
    }
    case types.FETCHING_COMPANY: {
      return { ...state, isFetching: true };
    }
    case types.FETCHING_FINISHED_COMPANY: {
      return { ...state, isFetching: false };
    }
    default:
      return state;
  }
}
