import { combineEpics, ofType } from 'redux-observable';
import {
  catchError, concatMap, endWith, mergeMap, startWith,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ADD_CONFIG } from '../../types/me';
import { findMe } from '../../utils/api/me';
import {
  addConfigFulfilled, clearFailConfig, failConfig, fetchConfig, fetchingFinishedConfig,
} from '../actions/config';

const addConfigEpic = (action$: any) =>
  action$.pipe(
    ofType(ADD_CONFIG),
    mergeMap(() =>
      from(findMe()).pipe(
        concatMap((response) => of(
          addConfigFulfilled(response.config),
          clearFailConfig(),
          fetchingFinishedConfig(),
        )),
        startWith(fetchConfig()),
        catchError((errors) => of(failConfig(errors.response.data.errors))),
        endWith(fetchingFinishedConfig()),
      )),
  );

export default combineEpics(addConfigEpic);
