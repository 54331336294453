import TagManager from 'react-gtm-module';

export const useTracking = (
  trackingId: string | undefined = process.env.GA_MEASUREMENT_ID,
): void => {
  if ((trackingId === undefined)) return;

  TagManager.initialize({ gtmId: trackingId });
};

export default useTracking;
