import { ErrorMessage } from './error';
import { Company } from './company';

export const ADD_USERS = 'ADD_USERS';
export const ADD_USERS_FULFILLED = 'ADD_USERS_FULFILLED';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_FULFILLED = 'ADD_USER_FULFILLED';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_FULFILLED = 'UPDATE_USER_FULFILLED';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_FULFILLED = 'DELETE_USER_FULFILLED';
export const ACTIVATE_USER = 'ACTIVATE_USER';
export const ACTIVATE_USER_FULFILLED = 'ACTIVATE_USER_FULFILLED';
export const ARCHIVE_USER = 'ARCHIVE_USER';
export const ARCHIVE_INVITATION_USER = 'ARCHIVE_INVITATION_USER';
export const ARCHIVE_USER_FULFILLED = 'ARCHIVE_USER_FULFILLED';
export const ADD_INVITATION_USERS = 'ADD_INVITATION_USERS';
export const ADD_INVITATION_USERS_FULFILLED = 'ADD_INVITATION_USERS_FULFILLED';
export const ADD_INVITATION_USER = 'ADD_INVITATION_USER';
export const FETCHING_USER = 'FETCHING_USER';
export const FETCHING_FINISHED_USER = 'FETCHING_FINISHED_USER';
export const FAIL_USER_REQUEST = 'FAIL_USER_REQUEST';
export const CLEAR_FAIL_USER_REQUEST = 'CLEAR_FAIL_USER_REQUEST';
export type RoleEnum = 'Owner' | 'Manager' | 'General' | undefined;
export type StatusEnum = 'NonActive' | 'Active' | 'InProcessOfAuthentication';

export interface User {
  id?: number;
  fullName?: string;
  lastName?: string;
  firstName?: string;
  email: string;
  password?: string;
  photoUrl?: string;
  role: RoleEnum;
  status: StatusEnum;
  messageAll?: boolean;
  messageMention?: boolean;
  confirmedAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  archivedAt?: Date;
  deletedAt?: Date;
}

export interface Operator {
  id?: number;
  name: string;
  photoUrl?: string;
}

export interface UserState {
  isFetching?: boolean;
  errors: ErrorMessage[];
  data: User[];
  company?: Company;
  count?: number;
}

export interface AddUsersAction {
  type: typeof ADD_USERS;
  payload: { query: string };
}

export interface AddUsersFulfilledAction {
  type: typeof ADD_USERS_FULFILLED;
  payload: { users: User[], count: number };
}

export interface AddUserAction {
  type: typeof ADD_USER;
  payload: { user: User };
}

export interface AddUserFulfilledAction {
  type: typeof ADD_USER_FULFILLED;
  payload: { user: User };
}

export interface UpdateUserAction {
  type: typeof UPDATE_USER;
  payload: { user: User };
}

export interface UpdateUserFulfilledAction {
  type: typeof UPDATE_USER_FULFILLED;
  payload: { user: User };
}

export interface DeleteUserAction {
  type: typeof DELETE_USER;
  payload: { id: number };
}

export interface DeleteUserFulfilledAction {
  type: typeof DELETE_USER_FULFILLED;
  payload: { id: number };
}

export interface ActivateUserAction {
  type: typeof ACTIVATE_USER;
  payload: { id: number };
}

export interface ActivateUserFullfilledAction {
  type: typeof ACTIVATE_USER_FULFILLED;
  payload: { id: number };
}

export interface ArchiveUserAction {
  type: typeof ARCHIVE_USER;
  payload: { id: number };
}

export interface ArchiveInvitationUserAction {
  type: typeof ARCHIVE_INVITATION_USER;
  payload: { id: number, invitedCompanyid: number };
}

export interface ArchiveUserFullfilledAction {
  type: typeof ARCHIVE_USER_FULFILLED;
  payload: { id: number };
}

export interface AddInvitationUsersAction {
  type: typeof ADD_INVITATION_USERS;
  payload: { invitedCompanyid: number, query: string };
}

export interface AddInvitationUsersFulfilledAction {
  type: typeof ADD_INVITATION_USERS_FULFILLED;
  payload: { users: User[], company: Company, count: number };
}

export interface AddInvitationUserAction {
  type: typeof ADD_INVITATION_USER;
  payload: { invitedCompanyid: number, user: User };
}

export interface FechingUserAction {
  type: typeof FETCHING_USER;
  payload: {};
}

export interface FechingFinishedUserAction {
  type: typeof FETCHING_FINISHED_USER;
  payload: {};
}

export interface FailUserRequestAction {
  type: typeof FAIL_USER_REQUEST;
  payload: { errors: ErrorMessage[] };
}

export interface ClearFailUserRequestAction {
  type: typeof CLEAR_FAIL_USER_REQUEST;
  payload: {};
}

export type UserActionTypes =
  AddUsersAction
  | AddUsersFulfilledAction
  | AddUserAction
  | AddUserFulfilledAction
  | UpdateUserAction
  | UpdateUserFulfilledAction
  | DeleteUserAction
  | DeleteUserFulfilledAction
  | ActivateUserAction
  | ActivateUserFullfilledAction
  | ArchiveUserAction
  | ArchiveInvitationUserAction
  | ArchiveUserFullfilledAction
  | AddInvitationUserAction
  | AddInvitationUsersAction
  | AddInvitationUsersFulfilledAction
  | FechingUserAction
  | FechingFinishedUserAction
  | FailUserRequestAction
  | ClearFailUserRequestAction;
