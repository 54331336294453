import { ErrorMessage } from './error';
import { User } from './user';
import { Company } from './company';

export const ADD_INVITATIONS = 'ADD_INVITATIONS';
export const ADD_INVITATIONS_FULFILLED = 'ADD_INVITATIONS_FULFILLED';
export const ADD_INVITATION = 'ADD_INVITATION';
export const ADD_INVITATION_FULFILLED = 'ADD_INVITATION_FULFILLED';
export const DELETE_INVITATION = 'DELETE_INVITATION';
export const DELETE_INVITATION_FULFILLED = 'DELETE_INVITATION_FULFILLED';
export const ARCHIVE_INVITATION = 'ARCHIVE_INVITATION';
export const ARCHIVE_INVITATION_FULFILLED = 'ARCHIVE_INVITATION_FULFILLED';
export const ACTIVATE_INVITATION = 'ACTIVATE_INVITATION';
export const ACTIVATE_INVITATION_FULFILLED = 'ACTIVATE_INVITATION_FULFILLED';
export const FETCHING_INVITATION = 'FETCHING_INVITATION';
export const FETCHING_FINISHED_INVITATION = 'FETCHING_FINISHED_INVITATION';
export const FAIL_INVITATION_REQUEST = 'FAIL_INVITATION_REQUEST';
export const CLEAR_FAIL_INVITATION_REQUEST = 'CLEAR_FAIL_INVITATION_REQUEST';

export interface Invitation {
  invitedOwnerUser: User;
  invitedCompany: Company;
  createdAt?: Date;
  updatedAt?: Date;
  archiveAt?: Date;
  deletedAt?: Date;
}

// NOTE:swaggerにあわせるため
export interface InvitationForPost {
  company: {
    name: string;
  };
}

export interface InvitationState {
  isFetching?: boolean;
  errors: ErrorMessage[];
  data: Invitation[];
  count?: number;
}

export interface AddInvitationsAction {
  type: typeof ADD_INVITATIONS;
  payload: { query: string };
}

export interface AddInvitationsFulfilledAction {
  type: typeof ADD_INVITATIONS_FULFILLED;
  payload: { invitations: Invitation[], count: number };
}

export interface AddInvitationAction {
  type: typeof ADD_INVITATION;
  payload: { invitationForPost: InvitationForPost };
}

export interface AddInvitationFulfilledAction {
  type: typeof ADD_INVITATION_FULFILLED;
  payload: { invitation: Invitation };
}

export interface DeleteInvitationAction {
  type: typeof DELETE_INVITATION;
  payload: { invitedCompanyId: number };
}

export interface DeleteInvitationFulfilledAction {
  type: typeof DELETE_INVITATION_FULFILLED;
  payload: { invitedCompanyId: number };
}

export interface ArchiveInvitationAction {
  type: typeof ARCHIVE_INVITATION;
  payload: { invitedCompanyId: number };
}

export interface ArchiveInvitationFulfilledAction {
  type: typeof ARCHIVE_INVITATION_FULFILLED;
  payload: { invitedCompanyId: number };
}

export interface ActivateInvitationsAction {
  type: typeof ACTIVATE_INVITATION;
  payload: { invitedOwnerUserId: number };
}

export interface ActivateInvitationFullfilledAction {
  type: typeof ACTIVATE_INVITATION_FULFILLED;
  payload: { invitedOwnerUserId: number };
}

export interface FechingInvitationAction {
  type: typeof FETCHING_INVITATION;
  payload: {};
}

export interface FechingFinishedInvitationAction {
  type: typeof FETCHING_FINISHED_INVITATION;
  payload: {};
}

export interface FailInvitationRequestAction {
  type: typeof FAIL_INVITATION_REQUEST;
  payload: { errors: ErrorMessage[] };
}

export interface ClearFailInvitationRequestAction {
  type: typeof CLEAR_FAIL_INVITATION_REQUEST;
  payload: {};
}

export type InvitationActionTypes =
  AddInvitationsAction
  | AddInvitationsFulfilledAction
  | AddInvitationAction
  | AddInvitationFulfilledAction
  | DeleteInvitationAction
  | DeleteInvitationFulfilledAction
  | ArchiveInvitationAction
  | ArchiveInvitationFulfilledAction
  | ActivateInvitationsAction
  | ActivateInvitationFullfilledAction
  | FechingInvitationAction
  | FechingFinishedInvitationAction
  | FailInvitationRequestAction
  | ClearFailInvitationRequestAction;
