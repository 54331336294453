import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/store';
import Logo from '../../../assets/img/logo.png';

export const HeaderLogo: React.FC = () => {
  const { config } = useSelector((s: RootState) => s);
  const rootPath = (): void => {
    window.location.href = `${process.env.REACT_APP_NEXT_PATH}${config?.rootMenu?.path}`;
  };

  return (
    <div className="flex-shrink-0">
      <button
        onClick={() => rootPath()}
      >
        <img className="h-10 w-auto" src={Logo} alt="logo" />
      </button>
    </div>
  );
};
