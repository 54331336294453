import { ofType } from 'redux-observable';
import {
  catchError, concatMap, mergeMap, startWith,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import { DELETE_USER } from '../../../types/user';
import * as types from '../../../types/user';
import { destroy } from '../../../utils/api/users';
import {
  clearFailUser, deleteUserFullfilled, failUser, fetchingFinishedUser, fetchingUser,
} from '../../actions/user';
import { closeModal, openModal } from '../../actions/modal';

export const deleteUserEpic = (action$: any) =>
  action$.pipe(
    ofType(DELETE_USER),
    mergeMap((action: types.DeleteUserAction) =>
      from(destroy(action.payload.id)).pipe(
        concatMap(() => of(
          deleteUserFullfilled(action.payload.id),
          fetchingFinishedUser(),
          clearFailUser(),
          closeModal(),
          // TODO: i18n対応。reduxで使うとハマる
          openModal({
            title: 'ユーザーをアーカイブしました。', message: '', type: 'success', buttons: true,
          }),
        )),
        startWith(fetchingUser()),
        catchError((errors) => of(
          failUser(errors.response.data.errors),
          fetchingFinishedUser(),
        )),
      )),
  );
