import { ofType, StateObservable } from 'redux-observable';
import { push } from 'connected-react-router';
import {
  catchError, concatMap, endWith, mergeMap, startWith,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as types from '../../../types/session';
import { RootState } from '../../../types/store';
import { updateOwnCompany } from '../../../utils/api/companies';
import {
  clearFailSession,
  failSession,
  fetchingFinishedSession,
  fetchingSession,
  updateCompanySessionFulfilled,
} from '../../actions/session';
import { addNotification } from '../../actions/notification';

export const updateCompanySessionEpic = (action$: any, state$: StateObservable<RootState>) =>
  action$.pipe(
    ofType(types.UPDATE_COMPANY_SESSION),
    mergeMap((action: types.UpdateCompanySessionAction) =>
      from(updateOwnCompany(action.payload.company)).pipe(
        concatMap((response) => of(
          updateCompanySessionFulfilled(response.company),
          clearFailSession(),
          push(`/${state$.value.session.company.contractType.toLowerCase()}/own_company`),
          addNotification({ message: '自社情報を更新しました。', type: 'success' }),
        )),
        startWith(fetchingSession()),
        catchError((errors) => of(failSession(errors.response.data.errors))),
        endWith(fetchingFinishedSession()),
      )),
  );
