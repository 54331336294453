import { ofType } from 'redux-observable';
import {
  catchError, concatMap, endWith, mergeMap, startWith,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as types from '../../../types/user';
import { activate } from '../../../utils/api/users';
import {
  activateUserFulfilled, clearFailUser, failUser, fetchingFinishedUser, fetchingUser,
} from '../../actions/user';
import { closeModal, openModal } from '../../actions/modal';

export const activateUserEpic = (action$: any) =>
  action$.pipe(
    ofType(types.ACTIVATE_USER),
    mergeMap((action: types.ActivateUserAction) =>
      from(activate(action.payload.id)).pipe(
        concatMap(() => of(
          activateUserFulfilled(action.payload.id),
          clearFailUser(),
          // TODO: i18n対応。reduxで使うとハマる
          closeModal(),
          // TODO: i18n対応。reduxで使うとハマる
          openModal({
            title: 'ユーザーをアクティブにしました。', message: '', type: 'success', buttons: true,
          }),
        )),
        startWith(fetchingUser()),
        catchError((errors) => of(failUser(errors.response.data.errors))),
        endWith(fetchingFinishedUser()),
      )),
  );
