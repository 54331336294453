import React from 'react';

interface Props {
  id: string;
  name: string;
  value: string;
  type: string;
  onChange: (e: any) => void;
  autoComplete: string;
  placeholder: string;
  required: boolean;
}

const TextInput: React.FC<Props> = (props) => {
  const {
    id,
    name,
    value,
    type,
    onChange,
    autoComplete,
    placeholder,
    required,
  } = props;
  return (
    <>
      <div className="mt-1">
        <input
          id={id}
          name={name}
          value={value}
          type={type}
          onChange={onChange}
          autoComplete={autoComplete}
          placeholder={placeholder}
          required={required}
          className="appearance-none block w-full px-3 py-2 border border-gray-300
                    rounded-md shadow-sm placeholder-gray-400 focus:outline-none
                    focus:ring-Navy-500 focus:border-Navy-500 sm:text-sm"
        />
      </div>
    </>
  );
};

export default TextInput;
