import axios from 'axios';
import { QueryClient } from 'react-query';
import { API_PATH } from './api';

// Define a default query function that will receive the query key
const defaultQueryFn = async ({ queryKey }: any) => {
  const path = queryKey[1];
  const { data } = await axios.get(
    `${API_PATH}${path}`,
    {
      withCredentials: true,
      timeout: 15000,
    },
  );
  return data;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 300000,
    },
  },
});
