import { ErrorMessage } from './error';

export const ADD_COMPANY = 'ADD_COMPANY';
export const ADD_COMPANY_FULFILLED = 'ADD_COMPANY_FULFILLED';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const UPDATE_COMPANY_FULFILLED = 'UPDATE_COMPANY_FULFILLED';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const DELETE_COMPANY_FULFILLED = 'DELETE_COMPANY_FULFILLED';
export const FETCHING_COMPANY = 'FETCHING_COMPANY';
export const FETCHING_FINISHED_COMPANY = 'FETCHING_FINISHED_COMPANY';
export const FAIL_COMPANY_REQUEST = 'FAIL_COMPANY_REQUEST';
export const CLEAR_FAIL_COMPANY_REQUEST = 'CLEAR_FAIL_COMPANY_REQUEST';
export type ContractTypeEnum = 'Admin' | 'Pro' | 'Client';
export interface Owner {
  id?: number;
  name: string;
  email: string;
}
export interface Company {
  id?: number;
  name: string;
  tel: string;
  presidentLastName: string;
  presidentFirstName: string;
  presidentTitle: string;
  zip: string;
  prefecture: string;
  city: string;
  street: string;
  building: string;
  contractType: ContractTypeEnum;
  owner: Owner;
  createdAt?: Date;
  updatedAt?: Date;
  archivedAt?: Date;
}

export interface CompanyState {
  isFetching?: boolean;
  errors: ErrorMessage[];
  data: Company[];
}

export interface AddCompanyAction {
  type: typeof ADD_COMPANY;
  payload: { company: Company };
}

export interface AddCompanyFulfilledAction {
  type: typeof ADD_COMPANY_FULFILLED;
  payload: { company: Company };
}

export interface UpdateCompanyAction {
  type: typeof UPDATE_COMPANY;
  payload: { company: Company };
}

export interface UpdateCompanyFullfilledAction {
  type: typeof UPDATE_COMPANY_FULFILLED;
  payload: { company: Company };
}

export interface DeleteCompanyAction {
  type: typeof DELETE_COMPANY;
  payload: { id: number };
}

export interface DeleteCompanyFullfilledAction {
  type: typeof DELETE_COMPANY_FULFILLED;
  payload: { id: number };
}

export interface FechingCompanyAction {
  type: typeof FETCHING_COMPANY;
  payload: {};
}

export interface FechingFinishedCompanyAction {
  type: typeof FETCHING_FINISHED_COMPANY;
  payload: {};
}

export interface FailCompanyRequestAction {
  type: typeof FAIL_COMPANY_REQUEST;
  payload: { errors: ErrorMessage[] };
}

export interface ClearFailCompanyRequestAction {
  type: typeof CLEAR_FAIL_COMPANY_REQUEST;
  payload: {};
}

export type CompanyActionTypes =
  AddCompanyAction
  | AddCompanyFulfilledAction
  | UpdateCompanyAction
  | UpdateCompanyFullfilledAction
  | DeleteCompanyAction
  | DeleteCompanyFullfilledAction
  | FechingCompanyAction
  | FechingFinishedCompanyAction
  | FailCompanyRequestAction
  | ClearFailCompanyRequestAction;
