import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translation from './ja/translation.json';

const resources = {
  ja: {
    translation,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: 'ja', resources,
});

export default i18n;
