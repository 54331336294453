import { ofType } from 'redux-observable';
import { push } from 'connected-react-router';
import { from, of } from 'rxjs';
import {
  catchError, concatMap, endWith, mergeMap, startWith,
} from 'rxjs/operators';
import { DELETE_ARTICLE } from '../../../types/article';
import * as types from '../../../types/article';
import { destroy } from '../../../utils/api/articles';
import {
  clearFailArticle,
  deleteArticleFulfilled,
  failArticle,
  fetchingFinishedArticle,
  fetchingArticle,
} from '../../actions/article';
import { addNotification } from '../../actions/notification';
import { closeModal } from '../../actions/modal';

export const deleteArticleEpic = (action$: any) =>
  action$.pipe(
    ofType(DELETE_ARTICLE),
    mergeMap((action: types.DeleteArticleAction) =>
      from(destroy(action.payload.id)).pipe(
        concatMap(() => of(
          deleteArticleFulfilled(action.payload.id),
          clearFailArticle(),
          // TODO: i18n対応。reduxで使うとハマる
          addNotification({ message: '削除しました。', type: 'success' }),
          closeModal(),
          // NOTE: query paramsを動的に取得するのはかなり難しいので、固定値で対応
          push('/pro/articles?onlyDeleted=true&category=all'),
        )),
        startWith(fetchingArticle()),
        catchError((errors) => of(failArticle(errors.response.data.errors))),
        endWith(fetchingFinishedArticle()),
      )),
  );
