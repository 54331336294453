import { ofType, StateObservable } from 'redux-observable';
import {
  catchError, concatMap, endWith, mergeMap, startWith,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import { User } from '../../../types/user';
import { changeEmail } from '../../../utils/api/firebase';
import { update } from '../../../utils/api/users';
import { RootState } from '../../../types/store';
import { UPDATE_EMAIL_SESSION } from '../../../types/session';
import * as types from '../../../types/session';
import {
  clearFailSession,
  failSession,
  fetchingFinishedSession,
  fetchingSession,
  updateUserSessionFulfilled,
} from '../../actions/session';
import { addNotification } from '../../actions/notification';

const handleChangeEmail = async (
  password: string, oldEmail: string, newEmail: string, user: User,
): Promise<any> => {
  try {
    await changeEmail(password, oldEmail, newEmail);
    const newUser = { ...user, email: newEmail };
    // DBのemailも変更
    return await update(newUser);
  } catch (e: any) {
    throw Error(e);
  }
};

// email更新メール送信のためのepic
export const updateEmailSessionEpic = (action$: any, state$: StateObservable<RootState>) =>
  action$.pipe(
    ofType(UPDATE_EMAIL_SESSION),
    mergeMap((action: types.UpdateEmailSessionAction) =>
      from(
        handleChangeEmail(
          action.payload.password,
          action.payload.oldEmail,
          action.payload.newEmail,
          state$.value.session.user,
        ),
      ).pipe(
        concatMap((response) => of(
          updateUserSessionFulfilled(response.user),
          clearFailSession(),
          // TODO: i18n対応。reduxで使うとハマる
          addNotification({ message: 'メールアドレスを変更しました', type: 'success' }),
        )),
        startWith(fetchingSession()),
        catchError(() => of(failSession([{ message: '変更に失敗しました' }]))),
        endWith(fetchingFinishedSession()),
      )),
  );
