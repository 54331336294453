import { ErrorMessage } from './error';
import { Operator } from './user';

export const ADD_ARTICLE_FOR_DRAFT = 'ADD_ARTICLE_FOR_DRAFT';
export const ADD_ARTICLE_FOR_SEND = 'ADD_ARTICLE_FOR_SEND';
export const ADD_ARTICLE_FULFILLED = 'ADD_ARTICLE_FULFILLED';
export const ADD_ARTICLES_FOR_SENT = 'ADD_ARTICLES_FOR_SENT';
export const ADD_ARTICLES_FOR_RECEIVE = 'ADD_ARTICLES_FOR_RECEIVE';
export const ADD_ARTICLES_FULFILLED = 'ADD_ARTICLES_FULFILLED';
export const UPDATE_ARTICLE_FOR_DRAFT = 'UPDATE_ARTICLE_FOR_DRAFT';
export const UPDATE_ARTICLE_FOR_SEND = 'UPDATE_ARTICLE_FOR_SEND';
export const UPDATE_ARTICLE_FULFILLED = 'UPDATE_ARTICLE_FULFILLED';
export const DELETE_ARTICLE = 'DELETE_ARTICLE';
export const DELETE_ARTICLE_FULFILLED = 'DELETE_ARTICLE_FULFILLED';
export const ACTIVATE_ARTICLE = 'ACTIVATE_ARTICLE';
export const ACTIVATE_ARTICLE_FULFILLED = 'ACTIVATE_ARTICLE_FULFILLED';
export const LIKE_ARTICLE = 'LIKE_ARTICLE';
export const LIKE_ARTICLE_FULFILLED = 'LIKE_ARTICLE_FULFILLED';
export const UN_LIKE_ARTICLE = 'UN_LIKE_ARTICLE';
export const UN_LIKE_ARTICLE_FULFILLED = 'UN_LIKE_ARTICLE_FULFILLED';
export const FETCHING_ARTICLE = 'FETCHING_ARTICLE';
export const FETCHING_FINISHED_ARTICLE = 'FETCHING_FINISHED_ARTICLE';
export const FAIL_ARTICLE_REQUEST = 'FAIL_ARTICLE_REQUEST';
export const CLEAR_FAIL_ARTICLE_REQUEST = 'CLEAR_FAIL_ARTICLE_REQUEST';

export type EditorMode = 'edit' | 'preview' | 'show';
export type SaveMode = 'draft' | 'send' | undefined;
export type ArticleType = 'draft' | 'send' | 'template';

export interface Article {
  id?: number;
  title: string;
  body: any; // NOTE: EditoJSのbodyをanyで扱うので、型もanyで対応
  text?: string;
  isNew?: boolean;
  imgFile?: string;
  contactCount?: number;
  contactedAt?: string;
  likeCount?: number;
  likedAt?: string;
  saveMode?: SaveMode;
  companies?: Array<{ id: number }>; // NOTE: 送信先設定で追加しているが、Pickなど使って後々分けたい
  createdUser?: Operator;
  updatedUser?: Operator;
  deletedUser?: Operator;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  draftAt?: Date;
  sentAt?: Date;
}

export interface ArticleState {
  isFetching?: boolean;
  errors: ErrorMessage[];
  data: Article[];
  count?: string;
}

export interface AddArticleActionForDraft {
  type: typeof ADD_ARTICLE_FOR_DRAFT;
  payload: { article: Article };
}

export interface AddArticleActionForSend {
  type: typeof ADD_ARTICLE_FOR_SEND;
  payload: { article: Article };
}

export interface AddArticleFulfilledAction {
  type: typeof ADD_ARTICLE_FULFILLED;
  payload: { article: Article };
}

export interface AddArticlesActionForSent {
  type: typeof ADD_ARTICLES_FOR_SENT;
  payload: { query: string };
}

export interface AddArticlesActionForReceive {
  type: typeof ADD_ARTICLES_FOR_RECEIVE;
  payload: { query: string };
}

export interface AddArticlesFulfilledAction {
  type: typeof ADD_ARTICLES_FULFILLED;
  payload: { articles: Article[], count: string };
}

export interface UpdateArticleActionForDraft {
  type: typeof UPDATE_ARTICLE_FOR_DRAFT;
  payload: { id: number, article: Article };
}

export interface UpdateArticleActionForSend {
  type: typeof UPDATE_ARTICLE_FOR_SEND;
  payload: { id: number, article: Article };
}

export interface UpdateArticleFullfilledAction {
  type: typeof UPDATE_ARTICLE_FULFILLED;
  payload: { article: Article };
}

export interface DeleteArticleAction {
  type: typeof DELETE_ARTICLE;
  payload: { id: number };
}

export interface DeleteArticleFullfilledAction {
  type: typeof DELETE_ARTICLE_FULFILLED;
  payload: { id: number };
}

export interface ActivateArticleAction {
  type: typeof ACTIVATE_ARTICLE;
  payload: { id: number };
}

export interface ActivateArticleFullfilledAction {
  type: typeof ACTIVATE_ARTICLE_FULFILLED;
  payload: { id: number };
}

export interface LikeArticleAction {
  type: typeof LIKE_ARTICLE;
  payload: { id: number };
}

export interface LikeArticleFullfilledAction {
  type: typeof LIKE_ARTICLE_FULFILLED;
  payload: { id: number };
}

export interface UnLikeArticleAction {
  type: typeof UN_LIKE_ARTICLE;
  payload: { id: number };
}

export interface UnLikeArticleFullfilledAction {
  type: typeof UN_LIKE_ARTICLE_FULFILLED;
  payload: { id: number };
}

export interface FechingArticleAction {
  type: typeof FETCHING_ARTICLE;
  payload: {};
}

export interface FechingFinishedArticleAction {
  type: typeof FETCHING_FINISHED_ARTICLE;
  payload: {};
}

export interface FailArticleRequestAction {
  type: typeof FAIL_ARTICLE_REQUEST;
  payload: { errors: ErrorMessage[] };
}

export interface ClearFailArticleRequestAction {
  type: typeof CLEAR_FAIL_ARTICLE_REQUEST;
  payload: {};
}

export type ArticleActionTypes =
  AddArticleActionForDraft
  | AddArticleActionForSend
  | AddArticleFulfilledAction
  | AddArticlesActionForSent
  | AddArticlesActionForReceive
  | AddArticlesFulfilledAction
  | UpdateArticleActionForDraft
  | UpdateArticleActionForSend
  | UpdateArticleFullfilledAction
  | DeleteArticleAction
  | DeleteArticleFullfilledAction
  | ActivateArticleAction
  | ActivateArticleFullfilledAction
  | LikeArticleAction
  | LikeArticleFullfilledAction
  | UnLikeArticleAction
  | UnLikeArticleFullfilledAction
  | FechingArticleAction
  | FechingFinishedArticleAction
  | FailArticleRequestAction
  | ClearFailArticleRequestAction;
