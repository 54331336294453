import * as types from '../../types/article';

const initialState: types.ArticleState = {
  isFetching: false,
  errors: [],
  data: [],
  count: '0',
} as types.ArticleState;

export default function articleReducer(
  state = initialState, action: types.ArticleActionTypes,
): types.ArticleState {
  switch (action.type) {
    case types.ADD_ARTICLE_FULFILLED: {
      const { article } = action.payload;
      const clone = state?.data;
      clone?.push(article);
      return { ...state, data: clone };
    }
    case types.ADD_ARTICLES_FULFILLED: {
      return { ...state, data: action.payload.articles, count: action.payload.count };
    }
    case types.UPDATE_ARTICLE_FULFILLED: {
      const { article } = action.payload;
      const clone = state.data;
      const index = clone.findIndex((obj: any) => obj.id === article.id);
      if (index !== -1) clone[index] = article;
      return { ...state, data: clone };
    }
    case types.DELETE_ARTICLE_FULFILLED: {
      const { id } = action.payload;
      const clone = state.data;
      const index = clone.findIndex((obj: any) => obj.id === id);
      if (index !== -1) clone.splice(index, 1);
      return { ...state, data: clone };
    }
    case types.ACTIVATE_ARTICLE_FULFILLED: {
      const { id } = action.payload;
      const clone = state.data;
      const index = clone.findIndex((obj: any) => obj.id === id);
      if (index !== -1) clone.splice(index, 1);
      return { ...state, data: clone };
    }
    case types.FETCHING_ARTICLE: {
      return { ...state, isFetching: true };
    }
    case types.FETCHING_FINISHED_ARTICLE: {
      return { ...state, isFetching: false };
    }
    case types.FAIL_ARTICLE_REQUEST: {
      const { errors } = action.payload;
      return { ...state, errors };
    }
    case types.CLEAR_FAIL_ARTICLE_REQUEST: {
      return { ...state, errors: [] };
    }
    default:
      return state;
  }
}
