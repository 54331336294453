import React, { Fragment, useContext } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { LoginIcon } from '@heroicons/react/solid';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { HeaderLogo } from '../atoms/Logos/Headerlogo';
import { HeaderProIcon } from '../atoms/Logos/HeaderProIcon';
import { RootState } from '../../types/store';
import { deleteSession } from '../../redux/actions/session';
import Icon from '../atoms/Logos/Icon';
import { MenuItem, PullDownMenuItem } from '../../types/me';
import { checkCurrentPath, classNames } from '../../utils/common_logic';
import UserIcon from '../atoms/Icons/UserIcon';
import { AuthContext } from '../../navigation/Auth';

const Header: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleLogout = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(deleteSession());
  };

  const { config, session } = useSelector((state: RootState) => state);
  const { state } = useContext(AuthContext);
  const { headerMenu, pullDownMenu } = config;
  const { user, company } = session;

  const mainMenus = [t('attribute.chat.title'), t('attribute.material.domainName'), t('pageTitle.invitations')];
  const newMainMenus = [t('attribute.chat.title'), t('attribute.material.domainName'), t('pageTitle.invitations'), t('pageTitle.workflow'), t('pageTitle.chatbot')];

  const transitionTopicsPage = (path: string) => {
    window.location.replace(`${process.env.REACT_APP_NEXT_PATH}${path}`);
  };

  const makePath = (path: string): string => {
    const newPullDownMenus = ['/me', '/me/edit/password', '/me/edit/email', '/me/edit/config', '/own_company', '/users'];

    if (newPullDownMenus.includes(path)) {
      return `${process.env.REACT_APP_NEXT_PATH}${path}`;
    } else {
      return path;
    }
  };

  return (
    <div className="min-h-full">
      <Disclosure as="nav" className="bg-Navy-800">
        <div className="mx-auto px-2 sm:px-6 lg:px-8 h-full">
          <div className="flex h-16 items-center justify-between">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <div className="w-32">
                  <HeaderLogo />
                </div>
              </div>
              {state.isLoggedIn && (
                <div className="hidden md:block">
                  <div className="ml-10 flex items-baseline space-x-4">
                    { headerMenu?.map((item: MenuItem) => (
                      newMainMenus.includes(item.text) ? (
                        <button
                          onClick={() => transitionTopicsPage(item.path)}
                          className={classNames(
                            checkCurrentPath(item.path) ? 'bg-Navy-600 text-white' : 'text-white hover:bg-Navy-900 hover:text-white',
                            'px-3 py-2 rounded-md text-sm font-medium',
                          )}
                          key={item.text}
                        >
                          { item.text }
                        </button>
                      ) : (
                        <Link
                          to={item.path}
                          className={classNames(
                            checkCurrentPath(item.path) ? 'bg-Navy-600 text-white' : 'text-white hover:bg-Navy-900 hover:text-white',
                            'px-3 py-2 rounded-md text-sm font-medium',
                          )}
                          key={item.text}
                        >
                          { item.text }
                        </Link>
                      )
                    ))}
                  </div>
                </div>
              )}
            </div>
            {state.isLoggedIn && (
              <div className="hidden md:flex h-full">
                <div className="ml-4 flex items-center md:ml-6">
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="mr-2 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                        <span className="sr-only">Open user menu</span>
                        <UserIcon photoUrl="" />
                        <p className="flex text-white text-sm mt-2 mx-3">
                          {user?.lastName}
                        &nbsp;&nbsp;
                          {user?.firstName}
                        </p>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        { pullDownMenu?.map((item: PullDownMenuItem) => (
                          <Menu.Item key={item.text}>
                            <a
                              href={makePath(item.path)}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              target={item.target}
                            >
                              <Icon icon={item.icon} className="float-left h-5 w-5 pr-1" aria />
                              {item.text}
                            </a>
                          </Menu.Item>
                        ))}
                        <a href="" onClick={(e) => handleLogout(e)} className="hover:bg-gray-100 block px-4 py-2 text-sm text-gray-700">
                          <LoginIcon className="float-left h-5 w-5 pr-1" aria-hidden="true" />
                          {t('action.logOut')}
                        </a>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                  {company?.contractType === 'Pro' && (
                  <div className="h-full">
                    <HeaderProIcon />
                  </div>
                  )}
                </div>
              </div>
            )}
            <div className="-mr-2 flex md:hidden">
              <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-Navy-200 hover:bg-opacity-75 hover:text-white focus:outline-none">
                <span className="sr-only">Open main menu</span>
                <UserIcon photoUrl="" />
              </Disclosure.Button>
            </div>
          </div>
        </div>

        <Disclosure.Panel className="md:hidden">
          <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
            { headerMenu?.map((item: MenuItem) =>
              (mainMenus.includes(item.text)) && (
                newMainMenus.includes(item.text) ? (
                  <Disclosure.Button
                    key={item.text}
                    as="a"
                    onClick={() => transitionTopicsPage(item.path)}
                    className="text-white hover:bg-Navy-500 hover:bg-opacity-75 block px-3 py-2 rounded-md text-base font-medium"
                  >
                    {item.text}
                  </Disclosure.Button>
                ) : (
                  <Disclosure.Button
                    key={item.text}
                    as="a"
                    href={item.path}
                    className="text-white hover:bg-Navy-500 hover:bg-opacity-75 block px-3 py-2 rounded-md text-base font-medium"
                  >
                    {item.text}
                  </Disclosure.Button>
                )
              ))}
          </div>
          <div className="border-t border-Navy-700 pt-4 pb-3">
            <div className="mt-3 space-y-1 px-2">
              <a href="" onClick={(e) => handleLogout(e)} className="hover:bg-gray-100 block px-4 py-2 text-sm text-white">
                {t('action.logOut')}
              </a>
            </div>
          </div>
        </Disclosure.Panel>
      </Disclosure>
    </div>
  );
};
export default Header;
